import React from "react";
import styled from "styled-components";
import { CellText } from "./CellText";
import moment from "moment";

export interface CellDateTimeProps {
  dateTimeText?: string;
  showTime: boolean;
  maxWidth?: number;
}

export const CellDateTime = ({ dateTimeText, showTime, maxWidth }: CellDateTimeProps) => {
  return (
    <>
      {dateTimeText && (
        <CellText maxWidth={maxWidth}>
          <DateTimeWrapper>
            <DateWrapper>{moment(new Date(dateTimeText)).format("yyyy-MM-DD")}</DateWrapper>
            {showTime && (
              <TimeWrapper>{moment(new Date(dateTimeText)).format("HH:mm:ss")}</TimeWrapper>
            )}
          </DateTimeWrapper>
        </CellText>
      )}
      {!dateTimeText && <CellText> </CellText>}
    </>
  );
};

const DateTimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
`;

const DateWrapper = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray100};
`;

const TimeWrapper = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray80};
`;
