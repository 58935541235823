import React, { useContext, useState } from "react";
import styled from "styled-components";
import useAuthService from "../../services/useAuthService";
import { AuthContext, EnvironmentContext } from "../../App";
import { Link } from "react-router-dom";
import { Environment } from "../../constatns/Environment";
import { resolveLoginError } from "../../utils/resolveLoginError";
import { LoginErrorCode } from "../../constatns/LoginErrorCode";
import { useDispatch } from "react-redux";
import { redirect, RoutePath } from "../../utils/redirect";
import { initApp } from "../../thunks/initAppThunk";
import { t } from "../../intl";
import PageLayout from "../../layouts/PageLayout";
import { Icon } from "../../components/PruIcon/Icon";
import { Button } from "../../components/Button/Button";
import { useForm } from "react-hook-form";
import { LoginPageFieldNames } from "./LoginPageFieldNames";
import { InputReactHookFormAdapter } from "../../components/PruInput/InputReactHookFormAdapter";

interface FormValues {
  [LoginPageFieldNames.username]: string;
  [LoginPageFieldNames.password]: string;
}

export interface LoginPageProps {
  setSpinnerVisible: (isVisible: boolean) => void;
}

export const LoginPage = (props: LoginPageProps) => {
  const dispatch = useDispatch();
  const { login, checkAuthentication } = useAuthService();
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [submitting, setSubmitting] = useState(false);
  const [capsLockEnabled, setCapsLockEnabled] = useState(false);

  const authContext = useContext(AuthContext);
  const environmentContext = useContext(EnvironmentContext);
  const isApEnvironment = environmentContext.environment === Environment.AP;

  const formMethods = useForm<FormValues>({
    mode: "onChange",
    reValidateMode: "onChange",
    criteriaMode: "all",
    defaultValues: {
      username: "",
      password: ""
    }
  });
  const { register, handleSubmit, formState, setValue, watch, reset } = formMethods;

  const data = watch();
  const submitDisabled = submitting || !formState.isValid || invalidCredentials;

  const onSubmit = (formData: FormValues) => {
    if (submitDisabled) {
      return;
    }
    const userName = formData[LoginPageFieldNames.username];
    const password = formData[LoginPageFieldNames.password];
    setErrorMessage(undefined);
    props.setSpinnerVisible(true);
    authContext.setUsername(userName);
    setSubmitting(true);
    login(userName, password)
      .then(() => checkAuthentication())
      .then(() => dispatch(initApp()))
      .catch(error => {
        let message: string | undefined;
        if (error?.errorCode) {
          switch (error.errorCode) {
            case LoginErrorCode.ACCOUNT_PASSWORD_MUST_CHANGE_ERROR_CODE:
            case LoginErrorCode.CREDENTIAL_EXPIRED_ERROR_CODE:
              if (isApEnvironment) {
                redirect(RoutePath.passwordExpired);
              }
              break;
            case LoginErrorCode.ACCOUNT_LOCKED_ERROR_CODE:
              if (isApEnvironment) {
                redirect(RoutePath.passwordChange);
              }
              break;
            case LoginErrorCode.INVALID_CREDENTIALS_ERROR_CODE:
              reset({ username: formData.username });
              setInvalidCredentials(true);
              break;
            case LoginErrorCode.ACCOUNT_DISABLED_ERROR_CODE:
              reset();
              break;
          }

          message = resolveLoginError(error.errorCode);
        }
        setErrorMessage(message || t("login.error.errorMessageDefault"));
      })
      .finally(() => {
        props.setSpinnerVisible(false);
        setSubmitting(false);
      });
  };

  const handleInputChanged = (value: string) => {
    setInvalidCredentials(false);
  };

  const handlePasswordKeyPress = (event: React.KeyboardEvent) => {
    if (event.getModifierState("CapsLock")) {
      setCapsLockEnabled(true);
    } else {
      setCapsLockEnabled(false);
    }
  };

  const showUserNameError =
    invalidCredentials || !!formState.errors[LoginPageFieldNames.username]?.types;

  const showPasswordError =
    invalidCredentials || !!formState.errors[LoginPageFieldNames.password]?.types;

  return (
    <PageLayout logoutVisible={false}>
      <LoginWrapper>
        <ShadowedBox width={440}>
          <FormWrapper onSubmit={handleSubmit(onSubmit)}>
            <TitleWrapper>
              <Title>{t("login.header", { br: <br /> })}</Title>
            </TitleWrapper>

            {errorMessage && (
              <ValidationWrapper>
                <MessageWrapper errorInside={true}>
                  <Message errorInside={true}>{errorMessage}</Message>
                  <Icon name="alert-triangle" color="error" />
                </MessageWrapper>
              </ValidationWrapper>
            )}

            {capsLockEnabled && (
              <ValidationWrapper>
                <MessageWrapper errorInside={false}>
                  <Message errorInside={false}>{t("login.warning.capsLockWarning")}</Message>
                  <Icon name="alert-triangle" color="warning" />
                </MessageWrapper>
              </ValidationWrapper>
            )}

            <InputWrapper>
              <InputReactHookFormAdapter
                labelProps={{
                  labelTrKey: "login.input.login.label"
                }}
                registerProps={register(LoginPageFieldNames.username, { required: true })}
                inputProps={{
                  value: data.username.trim(),
                  placeholderTrKey: "login.input.login.placeholder",
                  onValueChange: handleInputChanged,
                  onEnter: handleSubmit(onSubmit),
                  autoComplete: "off",
                  autoFocus: true
                }}
                isInvalid={showUserNameError}
                setValue={setValue}
              />
              <InputReactHookFormAdapter
                labelProps={{
                  labelTrKey: "login.input.password.label"
                }}
                registerProps={register(LoginPageFieldNames.password, { required: true })}
                inputProps={{
                  value: data.password,
                  placeholderTrKey: "login.input.password.placeholder",
                  onValueChange: handleInputChanged,
                  onEnter: handleSubmit(onSubmit),
                  onKeyPress: handlePasswordKeyPress,
                  autoComplete: "off",
                  inputType: "password"
                }}
                isInvalid={showPasswordError}
                setValue={setValue}
              />
              {environmentContext.environment === Environment.AP && (
                <LinksWrapper>
                  <StyledLink to="/password-change">{t("login.label.forgotPassword")}</StyledLink>
                  <StyledLink to="/password-change">{t("login.label.firstLogin")}</StyledLink>
                </LinksWrapper>
              )}
            </InputWrapper>
            <SubmitButton
              disabled={submitDisabled}
              buttonType="primary"
              type="submit"
              textTrKey={"login.button.submit"}
            />
          </FormWrapper>
        </ShadowedBox>
      </LoginWrapper>
    </PageLayout>
  );
};

const LoginWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 128px 48px 48px;
`;

const ShadowedBox = styled.div<{
  width?: number;
  padding?: number;
}>`
  max-width: 100vw;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.elevation1};

  ${({ width }) => (width && `width: ${width}px;`) || `width: auto;`}
  ${({ padding }) => (padding && `padding: ${padding}px;`) || `padding: 48px;`}
  ${({ theme }) => theme.media.belowMd} {
    padding: 10px 15px;
    border: none;
    box-shadow: none;
    background: none;
  }
`;

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Title = styled.div`
  font-weight: 600;
  text-align: center;
  font-size: 24px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.gray100};
`;

const ValidationWrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
`;

const MessageWrapper = styled.div<{ errorInside: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
  background: ${({ theme, errorInside }) =>
    errorInside ? theme.colors.errorBg : theme.colors.warningBg};
  border-radius: 4px;
  padding: 6px 12px;
  align-items: center;
  align-content: center;
  box-shadow: ${({ theme }) => theme.shadows.inset1};
`;

const Message = styled.div<{ errorInside: boolean }>`
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme, errorInside }) => (errorInside ? theme.colors.error : theme.colors.warning)};
  line-height: 20px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
`;

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledLink = styled(Link)`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.primary100};

  :hover {
    color: ${({ theme }) => theme.colors.gray100};
    text-decoration: none;
  }
  :active,
  :focus {
    color: ${({ theme }) => theme.colors.gray100};
    text-decoration: underline;
  }
`;

const SubmitButton = styled(Button)`
  padding: 16px 32px;
`;
