import React from "react";
import userIcon from "../../../assets/user.svg";
import { t } from "../../../intl";
import styled from "styled-components/macro";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

export const TilesUser = () => {
  const userInfo = useSelector((state: RootState) => state.userInfo);

  return (
    <TilesHeader>
      <UserIcon>
        <img src={userIcon} />
      </UserIcon>
      <UserDetails>
        <UserName>{userInfo.name}</UserName>
        <UserTitle>{t("tiles.label.prudentialAgent")}</UserTitle>
      </UserDetails>
    </TilesHeader>
  );
};

const TilesHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

const UserIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid ${({ theme }) => theme.colors.primary100};
  background-color: ${({ theme }) => theme.colors.white};
  height: 96px;
  width: 96px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
`;

const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const UserName = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
`;

const UserTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.primary100};
`;
