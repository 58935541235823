export type Theme = typeof defaultTheme;

export const defaultTheme = {
  colors: {
    // PRIMARY
    primary100: "#055A60",
    primaryDark: "#033D41",
    primaryDisabled: "#78ADB0",
    primary80: "#17B0AD",
    primary60: "#9DDEDD",
    primary40: "#BCE9E8",
    primary20: "#DCF3F3",
    primary10: "#EDF9F9",
    primary05: "#F9FDFD",

    // SECONDARY
    secondary100: "#EB5C37",
    secondaryDark: "#D13C15",
    secondary80: "#EF7D59",
    secondary60: "#F89B7E",
    secondary40: "#FAB9A4",
    secondary20: "#F8D8CE",
    secondary10: "#FBE8E2",
    secondary05: "#FFF6F3",

    // GRAY
    gray100: "#2F2F2F",
    gray100_07: "#2F2F2FB3",
    grayDark: "#181818",
    gray90: "#464646",
    gray80: "#737373",
    gray60: "#999999",
    gray40: "#D1D1D1",
    gray30: "#E3E3E3",
    gray20: "#EDEDED",
    gray10: "#F3F3F3",
    gray05: "#F8F8F8",

    // STATES
    error: "#D13C15",
    errorBg: "#FBEFEC",
    errorBg2: "rgba(209, 60, 21, 0.08)",
    warning: "#FFB81C",
    warningBg: "#FFF6E4",
    success: "#7EBD5F",
    successBg: "#F0F7EC",
    info: "#65B4E5",
    infoBg: "#EDF6FC",

    // MISC
    violet: "#7B76B6",
    black: "#000000",
    white: "#FFFFFF",
    neutral10: "#F0F0F0",
    transparent: "transparent",
    disabledBorder: "#DBDBDB"
  },
  shadows: {
    elevation1: "0px 1px 1px rgba(10, 27, 56, 0.24), 0px 0px 1px rgba(10, 27, 56, 0.32)",
    elevation2: "0px 1px 2px rgba(10, 27, 56, 0.08), 0px 2px 4px rgba(10, 27, 56, 0.12)",
    elevation3: "0px 8px 12px rgba(10, 27, 56, 0.16), 0px 0px 1px rgba(10, 27, 56, 0.32)",
    elevation4: "0px 10px 16px rgba(10, 27, 56, 0.16), 0px 0px 1px rgba(10, 27, 56, 0.32)",
    elevation5: "0px 18px 28px rgba(10, 27, 56, 0.16), 0px 0px 1px rgba(10, 27, 56, 0.32)",
    inset1: "inset 0px 1px 2px rgba(10, 27, 56, 0.08), inset 0px 2px 4px rgba(10, 27, 56, 0.12)"
  },
  fontFamily: {
    primary: "Nunito Sans"
  },
  media: {
    belowLg: "@media (max-width: 991px)",
    belowMd: "@media (max-width: 767px)",
    aboveMd: "@media (min-width: 768px)",
    belowSm: "@media (max-width: 575px)"
  },
  opacityHover: 0.6,
  opacityDisabled: 0.6
};

export type DefaultThemeColor = keyof typeof defaultTheme.colors;
