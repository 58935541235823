import React from "react";
import styled, { css } from "styled-components";

interface PeselInputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

export const PeselInput = React.forwardRef<HTMLInputElement, PeselInputProps>((props, ref) => {
  const { disabled } = props;
  return (
    <StyledInput
      {...props}
      disabled={!!disabled}
      autoComplete="off"
      maxLength={1}
      type={"password"}
      ref={ref}
    />
  );
});

const StyledInput = styled.input<{ disabled: boolean }>`
  width: 32px;
  height: 52px;
  margin: 0px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: ${({ theme }) => theme.colors.gray100};

  ${({ theme, disabled }) =>
    disabled
      ? css`
          disabled: true;
          background: ${theme.colors.neutral10};
          border: 1px solid ${theme.colors.disabledBorder};
        `
      : css`
          background: ${theme.colors.white};
          border: 2px solid ${theme.colors.gray100};
        `};

  &:focus {
    outline: 0;
  }
`;
