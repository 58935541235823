import React from "react";
import { useNavigate } from "react-router-dom";
import useApi from "../hooks/useApi";
import { api } from "../services/ApiService";
import { navigator } from "../utils/redirect";

export const Initializer = () => {
  const navigate = useNavigate();
  if (!navigator.navigate) {
    navigator.navigate = navigate;
  }
  const { doGet, doGetRaw, doPost, doDelete } = useApi();
  if (!api.get || !api.getRaw || !api.post || !api.delete) {
    api.get = doGet;
    api.getRaw = doGetRaw;
    api.post = doPost;
    api.delete = doDelete;
  }
  return null;
};
