import React, { useState } from "react";
import styled from "styled-components/macro";
import PageLayout from "../../layouts/PageLayout";
import { AvailableApp } from "../../models/AvailableApp";
import useAvailableAppsService from "../../services/useAvailableAppsService";
import { Tile } from "./Tile/Tile";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import useAuthService from "../../services/useAuthService";
import { BlockingNotifications } from "../../components/Notification/BlockingNotifications";
import { TilesUser } from "./TilesUser/TilesUser";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { Priority } from "../../constatns/Notification/Priority";
import { Status } from "../../constatns/Notification/Status";
import { TilesNotificationWarning } from "./TilesNotificationWarning/TilesNotificationWarning";
import { headerBelowLgLeftRightMargin, headerLeftRightMargin } from "../../constatns/layout";
import { useUser } from "../../hooks/useUser";

interface TilesPageProps {
  setSpinnerVisible: (isVisible: boolean) => void;
}

export const TilesPage = ({ setSpinnerVisible }: TilesPageProps) => {
  const [blockingExists, setBlockingExists] = useState(true);
  const { getApps } = useAvailableAppsService();
  const { logout } = useAuthService();
  const [loading, setLoading] = useState(true);
  const [tiles, setTiles] = useState<AvailableApp[]>([]);
  const [showNotificationWarning, setShowNotificationWarning] = useState(true);
  const { notifications } = useSelector((state: RootState) => state.notifications);
  const { isUserInfoLoaded } = useUser();

  if (!isUserInfoLoaded) {
    return null;
  }

  const getTiles = () => {
    setLoading(true);
    setSpinnerVisible(true);
    getApps()
      .then(response => {
        setTiles(response);
      })
      .finally(() => {
        setLoading(false);
        setSpinnerVisible(false);
      });
  };
  const handleAllBlockingRead = () => {
    setBlockingExists(false);
    getTiles();
  };

  const unreadHigh = notifications.filter(
    notification => notification.priority === Priority.high && notification.status !== Status.read
  ).length;

  const notificationWarningVisible = showNotificationWarning && unreadHigh > 0;

  return (
    <>
      {!loading && !tiles.length && (
        <ConfirmModal
          messageTrKey={"tiles.confirmModal.message"}
          confirmTrKey={"tiles.confirmModal.confirmText"}
          onConfirm={logout}
        />
      )}

      <PageLayout>
        <>
          {blockingExists && (
            <BlockingNotifications
              setSpinnerVisible={setSpinnerVisible}
              allBlockingRead={handleAllBlockingRead}
            />
          )}
          {!blockingExists && (
            <TilesWrapper>
              <TilesContent>
                <TilesUser />

                <Tiles>
                  {notificationWarningVisible && (
                    <TilesWarning>
                      <TilesNotificationWarning
                        unread={unreadHigh}
                        onClose={() => setShowNotificationWarning(false)}
                      />
                    </TilesWarning>
                  )}
                  {!notificationWarningVisible && <HorizontalLine />}
                  <TilesGrid>
                    {tiles.map(tile => (
                      <Tile key={tile.name} tile={tile} />
                    ))}
                  </TilesGrid>
                </Tiles>
              </TilesContent>
            </TilesWrapper>
          )}
        </>
      </PageLayout>
    </>
  );
};

const TilesWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.gray10};
  padding: 0px ${headerLeftRightMargin}px;

  ${({ theme }) => theme.media.belowLg} {
    padding: 0px ${headerBelowLgLeftRightMargin}px;
  }
`;

const TilesContent = styled.div`
  width: 100%;
  position: relative;
  top: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
`;

const Tiles = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 48px;
  justify-content: center;
  align-items: center;
  width: auto;
  max-width: fit-content;
  max-height: fit-content;
`;

const TilesWarning = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

const HorizontalLine = styled.div`
  width: 100%;
  height: 0px;
  border-top: 1px solid ${({ theme }) => theme.colors.gray30};
`;

const TilesGrid = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: auto;
  gap: 12px;
`;
