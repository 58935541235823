import React from "react";
import styled, { css } from "styled-components";

export interface CellProps {
  children: React.ReactNode;
  maxWidth?: number;
}

export const CellText = ({ children, maxWidth }: CellProps) => {
  return <StyledCell maxWidth={maxWidth}>{children}</StyledCell>;
};

const StyledCell = styled.div<{ maxWidth?: number }>`
  ${({ maxWidth }) => {
    return maxWidth
      ? css`
          max-width: ${maxWidth}px;
        `
      : "";
  }}
  font-weight: 400;
  padding: 8px 10px;
  @media (max-height: 960px) {
    padding: 3px 10px;
  }
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray100};
  overflow-wrap: break-word;
`;
