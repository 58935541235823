import { useCallback } from "react";
import { doDelete, doPost } from "./ApiService";

const useAuthService = () => {
  const checkAuthentication = useCallback(() => doPost("/home/api/user/init", undefined), [doPost]);

  const login = useCallback(
    (username: string, password: string) => {
      fetch(`/webmail/proxy/login`, {
        method: "GET",
        credentials: "same-origin"
      });
      return doPost(`/home/api/auth/login`, { username, password });
    },
    [doPost]
  );

  const changePassword = useCallback(
    (username: string, oldPassword: string, newPassword: string) => {
      return doPost(`/home/api/user/${username}/password-change`, { oldPassword, newPassword });
    },
    [doPost]
  );

  const logout = useCallback(
    () => doDelete("/home/api/session", undefined).finally(() => window.location.reload()),
    [doDelete]
  );

  const generateAuthorizationCode = useCallback(
    (username: string) =>
      doPost(`/home/api/user/${username}/password-reset/init`, undefined).then(response =>
        response.numbersToVerify.map((n: number) => n - 1)
      ),
    [doPost]
  );

  const confirmAuthorizationCode = useCallback(
    (username: string, verificationCharacters: string[]) =>
      doPost(`/home/api/user/${username}/password-reset/verify`, {
        verificationCharacters: verificationCharacters.join("")
      }).then(response => response.waitTimeInSeconds),
    [doPost]
  );

  const resetPassword = useCallback(
    (username: string, authorizationCode: string, newPassword: string) => {
      return doPost(`/home/api/user/${username}/password-reset/confirm`, {
        authorizationCode,
        newPassword
      });
    },
    [doPost]
  );

  const activateSessionInit = useCallback(() => {
    return doPost(`/home/api/user/activate-session/init`, undefined);
  }, [doPost]);

  const activateSessionConfirm = useCallback(
    (authorizationCode: string, trustDevice: string, deviceName: string) => {
      return doPost(`/home/api/user/activate-session/confirm`, {
        authorizationCode,
        trustDevice,
        deviceName
      });
    },
    [doPost]
  );

  return {
    login,
    logout,
    checkAuthentication,
    changePassword,
    generateAuthorizationCode,
    confirmAuthorizationCode,
    activateSessionInit,
    activateSessionConfirm,
    resetPassword
  };
};

export default useAuthService;
