import { useCallback } from "react";
import { doGet } from "./ApiService";

const useEnvironmentService = () => {
  const getEnvironment = useCallback(
    () => doGet("/home/api/environment").then(res => res.environment),
    [doGet]
  );

  return { getEnvironment };
};

export default useEnvironmentService;
