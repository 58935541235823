import React, { FC, useCallback } from "react";
import styled from "styled-components/macro";
import logo from "../../assets/logo.svg";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import { useModal } from "react-modal-hook";
import useAuthService from "../../services/useAuthService";
import { redirect, RoutePath } from "../../utils/redirect";
import { useLocation } from "react-router-dom";
import {
  headerBelowLgLeftRightMargin,
  headerHeight,
  headerLeftRightMargin
} from "../../constatns/layout";
import { CurrentLocation } from "../CurrentLocation/CurrentLocation";
import { NotificationsIcon } from "../Notification/NotificationsIcon";
import { HeaderIcon } from "./HeaderIcon";
import logoutImage from "../../assets/logout.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

interface Props {
  logoutVisible?: boolean;
  onHomeClick: () => void;
}

const Header: FC<Props> = ({ logoutVisible = true, onHomeClick }) => {
  const { logout } = useAuthService();
  const userInfo = useSelector((state: RootState) => state.userInfo);

  const [showModal, hideModal] = useModal(() => (
    <ConfirmModal
      headerTrKey={"header.logoutConfirmText"}
      confirmTrKey={"header.logoutConfirmButtonText"}
      messageTrKey={"header.logoutConfirmTextMessage"}
      onConfirm={logout}
      onCancel={hideModal}
      cancelTrKey={"button.cancel"}
      maxWidth={640}
      pictureSrc={logoutImage}
    />
  ));
  const location = useLocation();
  const goToTiles = useCallback(() => {
    if (location.pathname !== RoutePath.apps) {
      redirect(RoutePath.apps);
    }
  }, []);
  const currentLocationVisible = [RoutePath.apps, RoutePath.notifications].includes(
    location.pathname as RoutePath
  );
  const homeIconVisible = [RoutePath.apps, RoutePath.notifications].includes(
    location.pathname as RoutePath
  );
  const notificationIconVisible = [RoutePath.apps, RoutePath.notifications].includes(
    location.pathname as RoutePath
  );
  return (
    <Wrapper
      iconsVisible={
        logoutVisible || currentLocationVisible || homeIconVisible || notificationIconVisible
      }
    >
      {(homeIconVisible || currentLocationVisible) && (
        <HomeWrapper>
          {homeIconVisible && <HeaderIcon name={"home"} action={goToTiles} />}
          {currentLocationVisible && <CurrentLocation />}
        </HomeWrapper>
      )}
      <Logo src={logo} onClick={onHomeClick} />
      {logoutVisible && (
        <ElementWrapper>
          <UserName>{userInfo.name}</UserName>
          <IconsWrapper>
            {notificationIconVisible && <NotificationsIcon />}
            <HeaderIcon name={"log-out"} action={showModal} />
          </IconsWrapper>
        </ElementWrapper>
      )}
    </Wrapper>
  );
};

export default Header;

const Wrapper = styled.div<{ iconsVisible: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ iconsVisible }) => (iconsVisible ? "space-between" : "center")};
  gap: 20px;
  align-items: center;
  height: ${headerHeight}px;
  min-height: ${headerHeight}px;
  padding: 0px ${headerLeftRightMargin}px;
  background: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.media.belowLg} {
    padding: 0px ${headerBelowLgLeftRightMargin}px;
  }
`;

const ElementWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  width: 33.3%;
`;

const HomeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  width: 33.3%;
`;

const IconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

const Logo = styled.img`
  height: 100%;
  padding: 17px 0;
  cursor: pointer;
`;

const UserName = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray100};
`;
