import React from "react";
import styled, { css } from "styled-components";
import { Icon, IconWeight } from "../PruIcon/Icon";

interface CellUnreadProps {
  text: string;
  maxWidth?: number;
}

export const CellUnread = ({ text, maxWidth }: CellUnreadProps) => {
  return (
    <UnreadContent maxWidth={maxWidth}>
      <Icon name={"mail"} color={"primary100"} size={16} weight={IconWeight.normal} />
      <Text>{text}</Text>
    </UnreadContent>
  );
};

const UnreadContent = styled.div<{ maxWidth?: number }>`
  ${({ maxWidth }) => {
    return maxWidth
      ? css`
          max-width: ${maxWidth}px;
        `
      : "";
  }}
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 6px;
  height: 100%;
  gap: 8px;
  border-left-width: 6px;
  border-left-style: solid;
  border-left-color: ${({ theme }) => theme.colors.gray40};
`;

const Text = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray100};
  overflow-wrap: break-word;
`;
