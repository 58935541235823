import React from "react";
import { GridColumn } from "./GridColumn";
import { t } from "../../intl";
import styled, { css } from "styled-components";
import { GridState, SortDir } from "../../models/GridState";
import { TheadCell } from "./TheadCell";
import { GridPagination } from "./GridPagination";

interface GridProps<T> {
  columns: GridColumn<T>[];
  items: T[];
  idName: keyof T;
  gridState: GridState;
  total: number;
  onSortChanged: (fieldName: keyof T, dir: SortDir) => void;
  onSkipChanged: (skip: number) => void;
  onItemSelected: (item: T) => void;
}

export const Grid = <T extends {}>({
  columns,
  items,
  idName,
  gridState,
  total,
  onSortChanged,
  onSkipChanged,
  onItemSelected
}: GridProps<T>) => {
  const sortAsc = (fieldName: keyof T) => onSortChanged(fieldName, "asc");
  const sortDesc = (fieldName: keyof T) => onSortChanged(fieldName, "desc");

  const isSelected = (fieldName: keyof T, dir: SortDir) => {
    return gridState.sort?.find(item => item.field === fieldName && item.dir === dir) !== undefined;
  };
  const ascSelected = (fieldName: keyof T) => isSelected(fieldName, "asc");
  const descSelected = (fieldName: keyof T) => isSelected(fieldName, "desc");

  const resultsStart = items.length ? gridState.skip + 1 : 0;
  const resultsEnd = gridState.skip + items.length;

  return (
    <Table>
      <Thead>
        <TheadRow>
          {columns.map(column => (
            <TheadCell
              key={`${column.fieldName}`}
              text={column.text}
              onUp={() => sortAsc(column.fieldName)}
              onDown={() => sortDesc(column.fieldName)}
              upSelected={ascSelected(column.fieldName)}
              downSelected={descSelected(column.fieldName)}
              canSort={column.canSort}
            />
          ))}
        </TheadRow>
      </Thead>
      <TableBody>
        {items.map(item => (
          <Row
            key={`${item[idName]}`}
            onClick={() => onItemSelected(item)}
            canSelect={onItemSelected !== undefined}
          >
            {columns.map(column => (
              <Cell key={`${item[idName]}.${column.fieldName}`} maxWidth={column.maxWidth}>
                {column.renderCell(item)}
              </Cell>
            ))}
          </Row>
        ))}
      </TableBody>
      <Footer>
        <FooterRow>
          <FooterCell colSpan={columns.length}>
            <FooterCellContent>
              <Results>
                <ResultsText>{t("table.footerResults")}</ResultsText>
                <ResultsValue>
                  {resultsStart}-{resultsEnd}
                </ResultsValue>
                <ResultsText>{t("table.footerFrom")}</ResultsText>
                <ResultsValue>{total}</ResultsValue>
              </Results>
              {total > gridState.take && (
                <GridPagination
                  total={total}
                  skip={gridState.skip}
                  take={gridState.take}
                  onSkipChanged={onSkipChanged}
                />
              )}
            </FooterCellContent>
          </FooterCell>
        </FooterRow>
      </Footer>
    </Table>
  );
};

const Table = styled.table`
  width: 100%;
  /*1px trick: div inside td can get 100% td height*/
  height: 1px;
  display: table;
  border: 1px solid ${({ theme }) => theme.colors.gray30};
  border-spacing: 0px;
  border-collapse: collapse;
`;

const Thead = styled.thead`
  display: table-header-group;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray40};
`;

const TheadRow = styled.tr`
  display: table-row;
  border: 1px solid ${({ theme }) => theme.colors.gray30};
`;

const TableBody = styled.tbody`
  display: table-row-group;
`;

const Row = styled.tr<{ canSelect: boolean }>`
  display: table-row;

  :hover {
    ${({ canSelect }) =>
      canSelect
        ? css`
            cursor: pointer;
          `
        : ""};
  }
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray40};

  &:first-child {
    border-top: 1px solid ${({ theme }) => theme.colors.gray40};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray5};
  }
`;

const Cell = styled.td<{ maxWidth?: number }>`
  ${({ maxWidth }) =>
    maxWidth
      ? css`
          width: ${maxWidth}px;
          max-width: ${maxWidth}px;
        `
      : ""}
  height: 100%;
  padding: 0px;
  height: 56px;
  @media (max-height: 960px) {
    height: 46px;
  }
  position: relative;
  display: table-cell;
`;

const Footer = styled.tfoot`
  height: 56px;
  background: ${({ theme }) => theme.colors.gray05};
`;

const FooterRow = styled.tr``;

const FooterCell = styled.td``;

const FooterCellContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 24px;
`;

const Results = styled.span`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

const ResultsText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray100};
`;

const ResultsValue = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray100};
`;
