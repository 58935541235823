import React, { ReactNode } from "react";
import styled from "styled-components";
import Header from "../components/Header/Header";

const PageWrapper = styled.div`
  min-height: 100%;
  width: 100%;
  min-width: 600px;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  height: 100%;
  overflow-scrolling: auto;
  -webkit-overflow-scrolling: auto;
`; // Property -webkit-overflow-scrolling has to be set to auto when error overlay is active. Otherwise, there is a problem with stacking context in Safari.

interface PageLayoutProps {
  children: React.ReactElement;
  footer?: ReactNode;
  logoutVisible?: boolean;
}

const PageLayout = ({ footer, logoutVisible, children }: PageLayoutProps) => {
  const handleHomeClick = () => {
    window.location.reload();
  };

  return (
    <PageWrapper>
      <Header logoutVisible={logoutVisible} onHomeClick={handleHomeClick} />
      <Container id="page-container">{children}</Container>
      {footer}
    </PageWrapper>
  );
};

export default PageLayout;
