export enum AuthErrorCode {
  UNCAUGHT_EXCEPTION_ERROR_CODE = "000",
  SESSION_EXPIRED_ERROR_CODE = "001",
  NOT_FOUND_ERROR_CODE = "002",
  INVALID_PASSWORD_ERROR_CODE = "003",
  ATTRIBUTE_NOT_FOUND_ERROR_CODE = "004",
  SEND_NOTIFICATION_ERROR_CODE = "005",
  NOTIFICATION_THRESHOLD_EXCEEDED_ERROR_CODE = "006",
  INVALID_AUTHORIZATION_CODE_ERROR_CODE = "007",
  NOTIFICATION_CODE_EXPIRED_ERROR_CODE = "010",
  INVALID_LOGIN_ERROR_CODE = "011",
  PASSWORD_ENFORCE_HISTORY_ERROR_CODE = "013",
  INVALID_CODE_USAGE_EXCEEDED_ERROR_CODE = "016"
}
