import React from "react";
import styled from "styled-components/macro";
import { FieldErrors, useFormContext } from "react-hook-form";
import { NewPasswordFieldNames } from "./NewPasswordFieldNames";
import { t } from "../../intl";
import { Remark } from "./Remark";
import { UseFormTrigger } from "react-hook-form/dist/types/form";
import { InputReactHookFormAdapter } from "../PruInput/InputReactHookFormAdapter";

export interface NewPasswordFormValues {
  [NewPasswordFieldNames.newPassword]: string;
  [NewPasswordFieldNames.newPasswordRepeated]: string;
}

interface NewPasswordProps {
  errors: FieldErrors<NewPasswordFormValues>;
  trigger: UseFormTrigger<NewPasswordFormValues>;
}

export const NewPasswordForm = ({ errors, trigger }: NewPasswordProps) => {
  const { register, watch, setValue } = useFormContext();
  const handleNewPasswordChanged = (value: string) => {
    (async () => {
      await trigger(NewPasswordFieldNames.newPasswordRepeated);
    })();
  };
  const data = watch();
  return (
    <Wrapper>
      <InputWrapper>
        <InputReactHookFormAdapter
          labelProps={{
            labelTrKey: "newPassword.input.newPassword.label"
          }}
          registerProps={register(NewPasswordFieldNames.newPassword, {
            validate: {
              minMaxLength: (value: string) => value.length >= 12 && value.length <= 50,
              containsUppercaseLetter: (value: string) => value.toLowerCase() !== value,
              containsNumber: (value: string) => /\d/.test(value),
              containsSpecialCharacter: (value: string) =>
                /[-!$%^&*()_+|~=`{}[:;<>?,.@#\]]/g.test(value)
            }
          })}
          inputProps={{
            value: data.newPassword,
            placeholderTrKey: "newPassword.input.newPassword.placeholder",
            inputType: "password",
            onValueChange: handleNewPasswordChanged,
            autoComplete: "off"
          }}
          isInvalid={!!errors[NewPasswordFieldNames.newPassword]?.types}
          setValue={setValue}
        />
        <InputReactHookFormAdapter
          labelProps={{
            labelTrKey: "newPassword.input.newPasswordRepeated.label"
          }}
          registerProps={register(NewPasswordFieldNames.newPasswordRepeated, {
            required: true,
            validate: {
              requiredAndEqualToPassword: (value: string) => value && value === data.newPassword
            }
          })}
          inputProps={{
            value: data.newPasswordRepeated,
            placeholderTrKey: "newPassword.input.newPasswordRepeated.placeholder",
            inputType: "password",
            autoComplete: "off"
          }}
          isInvalid={
            !!errors[NewPasswordFieldNames.newPasswordRepeated]?.types?.requiredAndEqualToPassword
          }
          setValue={setValue}
        />
      </InputWrapper>
      <RemarksWrapper>
        <RemarksHeader>{t("newPassword.remarks.header")}</RemarksHeader>
        <Remarks>
          <Remark
            isValid={!errors[NewPasswordFieldNames.newPassword]?.types?.minMaxLength}
            textTrKey={"newPassword.remarks.atLeast12max50Chars"}
          />
          <Remark
            isValid={!errors[NewPasswordFieldNames.newPassword]?.types?.containsUppercaseLetter}
            textTrKey={"newPassword.remarks.atLeastOneUppercase"}
          />
          <Remark
            isValid={!errors[NewPasswordFieldNames.newPassword]?.types?.containsNumber}
            textTrKey={"newPassword.remarks.atLeastOneNumber"}
          />
          <Remark
            isValid={!errors[NewPasswordFieldNames.newPassword]?.types?.containsSpecialCharacter}
            textTrKey={"newPassword.remarks.atLeastOneSpecialCharacter"}
          />
        </Remarks>
      </RemarksWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 344px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const RemarksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const RemarksHeader = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray100};
`;

const Remarks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;
