import React, { FormEvent, useContext, useEffect } from "react";
import styled from "styled-components/macro";
import PageLayout from "../../layouts/PageLayout";
import useAuthService from "../../services/useAuthService";
import { FormProvider, useForm } from "react-hook-form";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import { useModal } from "react-modal-hook";
import { AuthErrorCode } from "../../constatns/AuthErrorCode";
import { AuthContext } from "../../App";
import { useLocation } from "react-router-dom";
import {
  NewPasswordForm,
  NewPasswordFormValues
} from "../../components/NewPasswordForm/NewPasswordForm";
import { NewPasswordFieldNames } from "../../components/NewPasswordForm/NewPasswordFieldNames";
import { ChangeExpiredPasswordFieldNames } from "./ChangeExpiredPasswordFieldNames";
import { redirect, RoutePath } from "../../utils/redirect";
import { UseFormTrigger } from "react-hook-form/dist/types/form";
import resetPasswordIcon from "../../assets/logout.svg";
import { t } from "../../intl";
import separator from "../../assets/separator.svg";
import { Button } from "../../components/Button/Button";
import { ReadOnlyField } from "../../components/ReadOnlyField/ReadOnlyField";
import { InputReactHookFormAdapter } from "../../components/PruInput/InputReactHookFormAdapter";

export interface ChangeExpiredPasswordFormValues extends NewPasswordFormValues {
  [ChangeExpiredPasswordFieldNames.oldPassword]: string;
}

export interface ChangePasswordPageProps {
  setSpinnerVisible: (isVisible: boolean) => void;
}

export const ChangeExpiredPasswordPage = (props: ChangePasswordPageProps) => {
  const { changePassword } = useAuthService();
  const authContext = useContext(AuthContext);
  const { state } = useLocation();
  const formMethods = useForm<ChangeExpiredPasswordFormValues>({
    mode: "onChange",
    reValidateMode: "onChange",
    criteriaMode: "all"
  });
  const { register, formState, watch, trigger, setValue, setFocus } = formMethods;

  useEffect(() => {
    // @ts-ignore
    if (!state.fromApp) {
      return redirect(RoutePath.home);
    }
    setFocus(ChangeExpiredPasswordFieldNames.oldPassword);
  }, [state, setFocus]);

  const data = watch();

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    await trigger(ChangeExpiredPasswordFieldNames.oldPassword);
    await trigger(NewPasswordFieldNames.newPassword);
    await trigger(NewPasswordFieldNames.newPasswordRepeated);
    if (!formState.isValid) {
      return;
    }

    props.setSpinnerVisible(true);
    changePassword(authContext.username, data.oldPassword, data.newPassword)
      .then(showSuccessfulMessage)
      .catch(error => {
        if (error?.errorCode === AuthErrorCode.INVALID_PASSWORD_ERROR_CODE) {
          resetNewPasswords();
        }
      })
      .finally(() => props.setSpinnerVisible(false));
  };

  const resetNewPasswords = () => {
    setValue(NewPasswordFieldNames.newPassword, "");
    setValue(NewPasswordFieldNames.newPasswordRepeated, "");
    trigger(NewPasswordFieldNames.newPassword).then(result => result);
  };

  const [showSuccessfulMessage, hideSuccessfulMessage] = useModal(
    () => (
      <ConfirmModal
        messageTrKey="expiredPassword.label.passwordHasBeenChanged"
        confirmTrKey="button.ok"
        onConfirm={() => {
          hideSuccessfulMessage();
          redirect(RoutePath.login);
        }}
      />
    ),
    []
  );

  const redirectToLogin = () => redirect(RoutePath.login);

  return (
    <PageLayout logoutVisible={false}>
      <Wrapper>
        <ResetPasswordHeader>
          <ResetPasswordIcon>
            <img src={resetPasswordIcon} alt={"Reset password"} />
          </ResetPasswordIcon>
          <HeaderText>{t("resetPassword.header")}</HeaderText>
          <HeaderSubText>{t("resetPassword.subHeader")}</HeaderSubText>
        </ResetPasswordHeader>

        <FormProvider {...formMethods}>
          <Form onSubmit={onSubmit}>
            <FormInputs>
              <PasswordForm>
                <ReadOnlyField
                  labelTrKey={"expiredPassword.label.userName"}
                  value={authContext.username}
                />
                <InputReactHookFormAdapter
                  labelProps={{
                    labelTrKey: "expiredPassword.input.oldPassword.label"
                  }}
                  registerProps={register(ChangeExpiredPasswordFieldNames.oldPassword, {
                    required: true
                  })}
                  inputProps={{
                    value: data.oldPassword,
                    placeholderTrKey: "expiredPassword.input.oldPassword.placeholder",
                    inputType: "password",
                    autoComplete: "off"
                  }}
                  isInvalid={!!formState.errors[ChangeExpiredPasswordFieldNames.oldPassword]?.types}
                  setValue={setValue}
                />
              </PasswordForm>
              <img src={separator} alt={"separator"} />
              <NewPasswordForm
                errors={formState.errors}
                trigger={trigger as unknown as UseFormTrigger<NewPasswordFormValues>}
              />
            </FormInputs>
            <FormButtons>
              <Button
                buttonType={"secondary"}
                onClick={redirectToLogin}
                leftIconName={"arrow-left"}
                textTrKey={"button.cancel"}
              />
              <Button
                buttonType={"primary"}
                type={"submit"}
                disabled={!formState.isValid}
                textTrKey={"button.next"}
              />
            </FormButtons>
          </Form>
        </FormProvider>
      </Wrapper>
    </PageLayout>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
  padding: 48px;
`;

const ResetPasswordHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
`;

const ResetPasswordIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  height: 80px;
  width: 80px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
`;

const HeaderText = styled.div`
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: ${({ theme }) => theme.colors.gray100};
`;

const HeaderSubText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray100};
`;

const Form = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
`;

const FormInputs = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
  padding: 32px;
  background: ${({ theme }) => theme.colors.white};
`;

const PasswordForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 344px;
`;

const FormButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;
