export const plMessages = {
  table: {
    footerResults: "Wyniki",
    footerFrom: "z"
  },
  currentRoute: {
    notifications: "Komunikaty",
    apps: "Aplikacje"
  },
  button: {
    back: "Wstecz",
    next: "Dalej",
    cancel: "Anuluj",
    ok: "OK"
  },
  api: {
    unexpectedAuthorizationError:
      "Niespodziewany błąd uwierzytelniania. Jeżeli potrzebujesz pomocy skontaktuj się z help-desk pod numerem: +48 459 595 100.",
    sessionExpired: "Sesja wygasła.",
    notFound: "Obiekt nie istnieje.",
    invalidPassword:
      "Hasło nie spełnia wymagań lub było użyte w ostatnich miesiącach. Podaj inne hasło.",
    attributeNotFound: "Atrybut nie znaleziony.",
    sendNotificationError: "Błąd wysyłania kodu autoryzacyjnego.",
    notificationThresholdExceeded: "Częstotliwość notyfikacji przekroczona.",
    invalidAuthorizationCode: "Niepoprawny kod autoryzacyjny.",
    internalServerError: "Wystąpił błąd wewnętrzny serwera",
    notificationCodeExpired:
      "Hasło tymczasowe nieważne. Użyj 'Ponownie wyślij SMS z hasłem jednorazowym'",
    invalidLogin: "Niepoprawne dane logowania",
    passwordEnforceHistory:
      "Hasło było już użyte. Wprowadź nowe hasło różne od używanych do tej pory",
    invalidCodeUsageExceeded:
      "Przekroczono liczbę prób użycia hasła tymczasowego. Skontaktuj się z helpdesk 459 595 100"
  },
  header: {
    logoutConfirmTextMessage: "Czy chcesz wylogować się z systemów Prudential?",
    logoutConfirmText: "Zakończenie sesji",
    logoutConfirmButtonText: "Tak, wyloguj mnie"
  },
  notification: {
    column: {
      publicationDate: "Data komunikatu",
      title: "Tytuł",
      notificationType: "Rodzaj",
      readDate: "Data odczytania",
      status: "Status"
    },
    button: {
      markAsRead: "Potwierdź odczytanie",
      postpone: "Odłóż na później",
      close: "Zamknij komunikat",
      search: "Szukaj",
      clearFilter: "Usuń filtr"
    },
    label: {
      title: "Tytuł komunikatu",
      publishDate: "Data komunikatu",
      status: "Status",
      priority: "Rodzaj komunikatu",
      unreadUrgentWarning:
        "UWAGA! Aby kontynować pracę w systemie PRU, musisz zapoznać się z BARDZO WAŻNYM komunikatem poniżej i potwierdzić zapoznanie się z jego treścią."
    },
    status: {
      read: "Odczytany",
      unread: "Nieodczytany"
    },
    priority: {
      urgent: "Bardzo ważny",
      high: "Ważny",
      info: "Informacyjny"
    },
    list: {
      label: "Lista komunikatów",
      searchPlaceholder: "Znajdź komunikat",
      filterNotificationTitleFind: "Znaleziono {notificationLength} wyników dla zwrotu",
      filterNotificationTitleEmpty: "Nie znaleziono wyników dla zwrotu",
      EmptyNotificationList: "Brak komunikatów do wyświetlenia"
    }
  },
  login: {
    header: "Logowanie dla {br} Przedstawiciela Prudential",
    input: {
      login: {
        label: "Login",
        placeholder: "Login"
      },
      password: {
        label: "Hasło",
        placeholder: "Hasło"
      }
    },
    label: {
      forgotPassword: "Nie pamiętam hasła",
      firstLogin: "Pierwsze logowanie"
    },
    warning: {
      capsLockWarning: "CapsLock jest włączony"
    },
    error: {
      errorMessageDefault:
        "Niespodziewany błąd uwierzytelniania. Jeżeli potrzebujesz pomocy skontaktuj się z help-desk pod numerem: +48 459 595 100."
    },
    button: {
      submit: "Zaloguj"
    }
  },
  mfa: {
    header: "Nie rozpoznaliśmy {br} tego urządzenia",
    subHeader: "Wpisz hasło tymczasowe wysłane {br} SMS-em na Twój numer komórkowy",
    input: {
      password: {
        placeholder: "Hasło tymczasowe",
        label: "Hasło tymczasowe"
      }
    },
    button: {
      sendSmsAgain: "Ponownie wyślij SMS z hasłem jednorazowym"
    },
    label: {
      addToTrusted:
        "Dodaj urządzenie do zaufanych lub odznacz pozycję w celu uzyskania jednorazowego dostępu"
    }
  },
  tiles: {
    label: {
      prudentialAgent: "Przedstawiciel Prudential"
    },
    unreadHighNotificationWarning: {
      message:
        "W Twoich powiadomieniach znajdują się nieodczytane komunikaty ze statusem WAŻNE ({unread}).",
      goToNotification: "Przejdź do komunikatów."
    },
    confirmModal: {
      message:
        "Brak dostępu do systemów Prudential. Skontaktuj się z infolinią pod numerem: +48 459 595 100.",
      confirmText: "Wyloguj"
    }
  },
  resetPassword: {
    header: "Zmiana hasła",
    subHeader: "Utwórz nowe hasło, które zostanie zaktualizowane dla systemów PRU.",
    input: {
      login: {
        label: "Login",
        placeholder: "Login"
      },
      temporaryPassword: {
        placeholder: "Hasło tymczasowe"
      }
    },
    button: {
      sendSmsWithTemporaryPassword: "Wyślij SMS z hasłem tymczasowym",
      sendSms: "Wyślij SMS"
    },
    label: {
      enterTemporaryPassword: "Wprowadź hasło tymczasowe",
      enterPeselCharacters: "Wpisz wskazane znaki numeru PESEL",
      temporaryPasswordHasBeenSent: "Hasło tymczasowe zostało wysłane",
      passwordHasBeenChanged: "Hasło zostało zmienione"
    }
  },
  newPassword: {
    input: {
      newPassword: {
        label: "Nowe hasło",
        placeholder: "Nowe hasło"
      },
      newPasswordRepeated: {
        label: "Powtórz nowe hasło",
        placeholder: "Powtórz nowe hasło"
      }
    },
    remarks: {
      header: "Hasło powinno zawierać:",
      atLeast12max50Chars: "minimum 12, maksimum 50 znaków",
      atLeastOneUppercase: "co najmniej jedną wielką literę",
      atLeastOneNumber: "co najmniej jedną cyfrę",
      atLeastOneSpecialCharacter: "co najmniej jeden znak specjalny (np. !@#$)"
    }
  },
  expiredPassword: {
    label: {
      userName: "Login",
      passwordHasBeenChanged: "Hasło zostało zmienione",
      passwordsAreNotTheSame: "Hasła nie są takie same",
      passwordDoesNotMeetTheMinimumRequirements: "Hasło nie spełnia minimalnych wymagań"
    },
    input: {
      oldPassword: {
        label: "Stare Hasło",
        placeholder: "Stare Hasło"
      }
    }
  }
};
