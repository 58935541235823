import React from "react";
import { Badge, BadgeProps } from "../Badge/Badge";
import { t } from "../../intl";
import { Status } from "../../constatns/Notification/Status";

interface NotificationStatusProps {
  status: Status;
  maxWidth?: number;
}

const getBadgeProps = (status: Status): BadgeProps => {
  switch (status) {
    case Status.read: {
      return { backgroundColor: "gray20", color: "gray100", rectangleColor: "gray80" };
    }
    case Status.unread: {
      return { backgroundColor: "errorBg", color: "gray100", rectangleColor: "error" };
    }
  }
};

export const NotificationStatus = ({ status, maxWidth }: NotificationStatusProps) => {
  return (
    <Badge
      {...getBadgeProps(status)}
      text={t(`notification.status.${status}`)}
      maxWidth={maxWidth}
    />
  );
};
