import React from "react";
import { GridColumn } from "../../components/Grid/GridColumn";
import { Notification, notificationFieldNames } from "../../models/Notification";
import { t } from "../../intl";
import { Status } from "../../constatns/Notification/Status";
import { CellUnread } from "../../components/Grid/CellUnread";
import { CellText } from "../../components/Grid/CellText";
import { CellDateTime } from "../../components/Grid/CellDateTime";
import { NotificationPriority } from "../../components/Notification/NotificationPriority";
import { NotificationStatus } from "../../components/Notification/NotificationStatus";

export const notificationColumns: GridColumn<Notification>[] = [
  {
    fieldName: notificationFieldNames.title,
    text: t("notification.column.title"),
    renderCell: (notification: Notification) => {
      return notification.status === Status.unread ? (
        <CellUnread text={notification.title} />
      ) : (
        <CellText>{notification.title}</CellText>
      );
    },
    canSort: true
  },
  {
    fieldName: notificationFieldNames.publishDate,
    text: t("notification.column.publicationDate"),
    renderCell: (notification: Notification) => (
      <CellDateTime dateTimeText={notification.publishDate} showTime={false} />
    ),
    canSort: true,
    maxWidth: 200
  },
  {
    fieldName: notificationFieldNames.readDate,
    text: t("notification.column.readDate"),
    renderCell: (notification: Notification) => (
      <CellDateTime dateTimeText={notification.readDate} showTime={true} />
    ),
    canSort: true,
    maxWidth: 200
  },
  {
    fieldName: notificationFieldNames.priority,
    text: t("notification.column.notificationType"),
    renderCell: (notification: Notification) => (
      <CellText>
        <NotificationPriority priority={notification.priority} />
      </CellText>
    ),
    canSort: true,
    maxWidth: 200
  },
  {
    fieldName: notificationFieldNames.status,
    text: t("notification.column.status"),
    renderCell: (notification: Notification) => (
      <CellText>
        <NotificationStatus status={notification.status} />
      </CellText>
    ),
    canSort: true,
    maxWidth: 200
  }
];
