import React, { FC, MouseEvent } from "react";
import styled from "styled-components/macro";
import { t } from "../../intl";
import { Icon } from "../PruIcon/Icon";

interface LinkButtonProps {
  textTrKey: string;
  onClick: (e: MouseEvent) => void;
  disabled?: boolean;
  leftIconName?: string;
  rightIconName?: string;
  className?: string;
}

export const LinkButton: FC<LinkButtonProps> = props => {
  const { disabled, textTrKey, onClick, leftIconName, rightIconName, className } = props;

  return (
    <StyledButton disabled={disabled} onClick={onClick} className={className} type={"button"}>
      {leftIconName && <LeftIcon name={leftIconName} size={16} />}
      {t(textTrKey)}
      {rightIconName && <RightIcon name={rightIconName} size={16} />}
    </StyledButton>
  );
};

const LeftIcon = styled(Icon)`
  margin-right: 8px;
`;

const RightIcon = styled(Icon)`
  margin-left: 8px;
`;

const StyledButton = styled.button`
  font-weight: 700;
  border-width: 0;
  outline: 0;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  position: relative;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.primary100};

  ${LeftIcon}, ${RightIcon} {
    svg g {
      &,
      path,
      circle,
      rect {
        stroke: ${({ theme }) => theme.colors.primary100} !important;
      }

      rect {
        fill: ${({ theme }) => theme.colors.primary100} !important;
      }

      tspan {
        stroke: transparent !important;
        fill: ${({ theme }) => theme.colors.primary100} !important;
      }
    }

    svg > path {
      stroke: ${({ theme }) => theme.colors.primary100} !important;
    }
  }

  &:hover:not(:disabled) {
    color: ${({ theme }) => theme.colors.gray100};
    text-decoration: none;

    ${LeftIcon}, ${RightIcon} {
      svg g {
        &,
        path,
        circle,
        rect {
          stroke: ${({ theme }) => theme.colors.gray100} !important;
        }

        rect {
          fill: ${({ theme }) => theme.colors.gray100} !important;
        }

        tspan {
          stroke: transparent !important;
          fill: ${({ theme }) => theme.colors.gray100} !important;
        }
      }

      svg > path {
        stroke: ${({ theme }) => theme.colors.gray100} !important;
      }
    }
  }

  &:active:not(:disabled) {
    text-decoration: underline;
  }

  &:disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.colors.primaryDisabled};

    ${LeftIcon}, ${RightIcon} {
      svg g {
        &,
        path,
        circle,
        rect {
          stroke: ${({ theme }) => theme.colors.primaryDisabled} !important;
        }

        rect {
          fill: ${({ theme }) => theme.colors.primaryDisabled} !important;
        }

        tspan {
          stroke: transparent !important;
          fill: ${({ theme }) => theme.colors.primaryDisabled} !important;
        }
      }

      svg > path {
        stroke: ${({ theme }) => theme.colors.primaryDisabled} !important;
      }
    }
  }
`;
