import React from "react";
import styled from "styled-components";
import { t } from "../../../intl";
import { redirect, RoutePath } from "../../../utils/redirect";
import { Warning } from "../../../components/Warning/Warning";

interface TilesNotificationWarningProps {
  unread: number;
  onClose: () => void;
}

export const TilesNotificationWarning = ({ unread, onClose }: TilesNotificationWarningProps) => {
  const goToNotifications = () => redirect(RoutePath.notifications);
  return (
    <Warning
      onClose={onClose}
      children={
        <TextWrapper>
          <Text>{t("tiles.unreadHighNotificationWarning.message", {unread: unread})}</Text>
          <Link onClick={goToNotifications}>
            {t("tiles.unreadHighNotificationWarning.goToNotification")}
          </Link>
        </TextWrapper>
      }
    />
  );
};

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Text = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray100};
`;

const Link = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.primary100};
  text-decoration: underline;
  margin-left: 5px;
  cursor: pointer;
`;
