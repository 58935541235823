import React, { FC } from "react";
import styled from "styled-components/macro";
import { t } from "../../intl";
import { TypoCaptionBold } from "../typography";
import { Icon } from "../PruIcon/Icon";

export enum ButtonType {
  primary = "primary",
  primaryBlack = "primaryBlack",
  secondary = "secondary"
}

export enum ButtonSize {
  lg = "lg",
  default = "default",
  sm = "sm"
}

export interface ButtonProps {
  textTrKey: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  buttonType?: keyof typeof ButtonType;
  buttonSize?: keyof typeof ButtonSize;
  disabled?: boolean;
  buttonWidth?: string;
  captionTrKey?: string;
  leftIconName?: string;
  rightIconName?: string;
  className?: string;
  type?: "submit" | "reset" | "button" | undefined;
}

export const Button: FC<ButtonProps> = props => {
  const {
    buttonType,
    buttonSize,
    disabled,
    textTrKey,
    onClick,
    buttonWidth,
    captionTrKey,
    className,
    leftIconName,
    rightIconName,
    type
  } = props;

  let ButtonComponent = ButtonBase;

  if (buttonType === ButtonType.primary) ButtonComponent = PrimaryButton;
  if (buttonType === ButtonType.primaryBlack) ButtonComponent = PrimaryBlackButton;
  if (buttonType === ButtonType.secondary) ButtonComponent = SecondaryButton;

  const iconParams =
    buttonSize === ButtonSize.sm ? { size: 14, spacing: 8 } : { size: 20, spacing: 12 };

  return (
    <ButtonComponent
      buttonSize={buttonSize}
      disabled={disabled}
      buttonWidth={buttonWidth}
      onClick={onClick}
      className={className}
      type={type}
    >
      <Text>
        {leftIconName && (
          <LeftIcon name={leftIconName} size={iconParams.size} iconSpacing={iconParams.spacing} />
        )}
        {t(textTrKey)}
        {rightIconName && (
          <RightIcon name={rightIconName} size={iconParams.size} iconSpacing={iconParams.spacing} />
        )}
      </Text>
      {captionTrKey && <TypoCaptionBold>{t(captionTrKey)}</TypoCaptionBold>}
    </ButtonComponent>
  );
};

Button.defaultProps = {
  buttonType: ButtonType.primary,
  buttonSize: ButtonSize.lg
};

export const LeftIcon = styled(Icon)<{ iconSpacing: number }>`
  margin-right: ${({ iconSpacing }) => iconSpacing}px;
`;

export const RightIcon = styled(Icon)<{ iconSpacing: number }>`
  margin-left: ${({ iconSpacing }) => iconSpacing}px;
`;

const ButtonBase = styled.button<{ buttonWidth?: string; buttonSize?: keyof typeof ButtonSize }>`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
  padding: 0 24px;
  border-width: 0;
  outline: 0;
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  line-height: 20px;

  ${({ buttonWidth }) => buttonWidth && `width: ${buttonWidth}`};

  ${({ buttonSize }) => {
    switch (buttonSize) {
      case ButtonSize.default:
        return `
          min-width: 128px;
          max-width: 400px;
          height: 44px;`;
      case ButtonSize.lg:
        return `
          min-width: 128px;
          max-width: 400px;
          height: 52px;`;
      case ButtonSize.sm:
        return `
          min-width: 84px;
          max-width: 340px;
          height: 28px;
          font-size: 14px;`;
    }
  }}
  &:active:not(:disabled) {
    transform: translateY(1px);
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const PrimaryButton = styled(ButtonBase)`
  background-color: ${({ theme }) => theme.colors.primary100};

  &:hover:not(:disabled) {
    background-color: ${({ theme }) => theme.colors.grayDark};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.primaryDisabled};
  }
`;

const PrimaryBlackButton = styled(ButtonBase)`
  background-color: ${({ theme }) => theme.colors.grayDark};

  &:hover:not(:disabled) {
    background-color: ${({ theme }) => theme.colors.primary100};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.gray60};
  }
`;

const SecondaryButton = styled(ButtonBase)`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.grayDark};
  border: 2px solid;
  border-color: ${({ theme }) => theme.colors.gray30};

  &:hover:not(:disabled) {
    border-color: ${({ theme }) => theme.colors.primary100};

    * {
      color: ${({ theme }) => theme.colors.primary100};
    }
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.gray20};
  }
`;

const Text = styled.div`
  display: flex;
  align-items: center;
`;
