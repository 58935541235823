import React from "react";
import styled from "styled-components/macro";
import { Icon } from "../PruIcon/Icon";
import { t } from "../../intl";

interface RemarkProps {
  textTrKey: string;
  isValid: boolean;
}

export const Remark = ({ textTrKey, isValid }: RemarkProps) => {
  return (
    <RemarkWrapper>
      <Icon name={isValid ? "check" : "alert-triangle"} color={isValid ? "success" : "error"} />
      <RemarkText isValid={isValid}>{t(textTrKey)}</RemarkText>
    </RemarkWrapper>
  );
};

const RemarkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 4px;
`;

const RemarkText = styled.div<{ isValid: boolean }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme, isValid }) => (isValid ? theme.colors.gray100 : theme.colors.error)};
`;
