import { store } from "../store";
import { useCallback } from "react";
import { Permission } from "../constatns/Permission";
import { LoadingStatus } from "../constatns/LoadingStatus";

export const useUser = () => {
  const userInfoState = store.getState().userInfo;
  const isUserInfoLoaded = userInfoState.loadingStatus === LoadingStatus.Loaded;
  const hasPermission = useCallback(
    (permission: Permission) => {
      return !!userInfoState.permissions.find(perm => permission === perm);
    },
    [userInfoState]
  );

  return {
    isUserInfoLoaded,
    hasPermission
  };
};
