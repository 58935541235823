import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { userInfoReducer } from "./slices/userInfoSlice";
import { notificationsReducer } from "./slices/notificationsSlice";

const rootReducer = combineReducers({
  userInfo: userInfoReducer,
  notifications: notificationsReducer
});

export const store = configureStore({
  reducer: rootReducer
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
