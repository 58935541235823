import React, { FC } from "react";
import styled from "styled-components/macro";
import { Modal } from "../Modal/Modal";
import { t } from "../../intl";

interface ConfirmModalProps {
  headerTrKey?: string;
  messageTrKey?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmTrKey?: string;
  cancelTrKey?: string;
  maxWidth?: number;
  pictureSrc?: string;
  zindex?: number;
}

const ConfirmModal: FC<ConfirmModalProps> = ({
  headerTrKey,
  messageTrKey,
  confirmTrKey,
  cancelTrKey,
  onCancel,
  onConfirm,
  maxWidth,
  pictureSrc,
  zindex
}) => {
  return (
    <Modal
      onConfirm={onConfirm}
      confirmTrKey={confirmTrKey}
      onCancel={onCancel}
      cancelTrKey={cancelTrKey || "button.cancel"}
      maxWidth={maxWidth}
      zindex={zindex}
    >
      <ContentWrapper>
        {pictureSrc && (
          <PictureWrapper>
            <Picture>
              <img  src={pictureSrc} />
            </Picture>
          </PictureWrapper>
        )}
        {headerTrKey && <TextHeader>{t(headerTrKey)}</TextHeader>}
        {messageTrKey && <Message>{t(messageTrKey)}</Message>}
      </ContentWrapper>
    </Modal>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

const PictureWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 120px;
  width: 120px;
`;

const Picture = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 88px;
  height: 88px;
`;

const TextHeader = styled.div`
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: ${({ theme }) => theme.colors.primary100};
  text-align: center;
  padding: 0px 24px;
`;

const Message = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray100};
`;

export default ConfirmModal;
