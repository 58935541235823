import React from "react";
import styled from "styled-components/macro";
import { t } from "../../intl";
import { TypoSmallRegular } from "../typography";
import { Tooltip } from "../Tooltip/Tooltip";

export interface LabelProps {
  labelTextStyles?: any;
  labelTrKey?: string;
  labelTrValues?: any;
  text?: string;
  handleLabelClick?: () => void;
  className?: any;
  tooltipTrKey?: string;
  disabled?: boolean;
}

export const Label: React.FC<LabelProps> = props => {
  const {
    labelTextStyles,
    labelTrKey,
    labelTrValues,
    text,
    handleLabelClick,
    className,
    tooltipTrKey,
    disabled
  } = props || {};

  return (
    <LabelContainer labelWithText={!!labelTrKey || !!text} className={className}>
      <LabelText labelTextStyles={labelTextStyles} disabled={disabled} onClick={handleLabelClick}>
        {text ?? (labelTrKey && t(labelTrKey, labelTrValues))}
      </LabelText>
      {tooltipTrKey && <Tooltip text={t(tooltipTrKey)} />}
    </LabelContainer>
  );
};

const LabelContainer = styled.div<{ labelWithText: boolean }>`
  position: relative;
  ${({ labelWithText }) => labelWithText && "margin-bottom: 4px"};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LabelText = styled(TypoSmallRegular)<{ labelTextStyles: any; disabled?: boolean }>`
  cursor: default;
  display: inline-block;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${({ theme, disabled }) => (!disabled ? theme.colors.gray100 : theme.colors.gray80)};
  ${({ labelTextStyles }) => labelTextStyles};
`;
