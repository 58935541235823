import React, { useCallback, useContext } from "react";
import { useUser } from "../../hooks/useUser";
import { Permission } from "../../constatns/Permission";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { notificationsActions } from "../../slices/notificationsSlice";
import { redirect, RoutePath } from "../../utils/redirect";
import styled from "styled-components/macro";
import { EnvironmentContext } from "../../App";
import { Environment } from "../../constatns/Environment";
import { HeaderIcon } from "../Header/HeaderIcon";

export const NotificationsIcon = () => {
  const dispatch = useDispatch();
  const { hasPermission } = useUser();
  const { environment } = useContext(EnvironmentContext);
  const showNotifications =
    hasPermission(Permission.AGENT_NOTIFICATIONS_FE) && environment === Environment.AP;
  const { totalUnread } = useSelector((state: RootState) => state.notifications);

  const handleToNotifications = useCallback(() => {
    dispatch(notificationsActions.resetNotifications());
    redirect(RoutePath.notifications);
  }, []);

  const unreadValue = totalUnread > 99 ? 99 : totalUnread;

  return (
    <>
      {showNotifications && (
        <Wrapper onClick={handleToNotifications}>
          <HeaderIcon name={"bell"} />
          {unreadValue > 0 && <Counter>{unreadValue}</Counter>}
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 48px;
  height: 48px;
  cursor: pointer;
`;

const Counter = styled.div`
  position: absolute;
  right: -5px;
  width: 20px;
  height: 20px;
  background: ${({ theme }) => theme.colors.error};
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;
