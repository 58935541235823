import React, { FC } from "react";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { DefaultThemeColor } from "../../theme/defaultTheme";

export interface BadgeProps {
  backgroundColor: DefaultThemeColor;
  color: DefaultThemeColor;
  rectangleColor: DefaultThemeColor;
  text?: string;
  maxWidth?: number;
}

export const Badge: FC<BadgeProps> = ({
  backgroundColor,
  color,
  rectangleColor,
  text,
  maxWidth
}) => {
  return (
    <WrapperContainer>
      <Wrapper maxWidth={maxWidth} backgroundColor={backgroundColor}>
        <Rectangle rectangleColor={rectangleColor} />
        <Text color={color}>{text}</Text>
      </Wrapper>
    </WrapperContainer>
  );
};

const WrapperContainer = styled.div`
  display: table-cell;
`;

const Wrapper = styled.div<{ backgroundColor: DefaultThemeColor; maxWidth?: number }>`
  padding: 0px 6px 0px 0px;
  gap: 8px;
  height: 20px;
  width: 100%;

  ${({ maxWidth }) => {
    return maxWidth
      ? css`
          max-width: ${maxWidth}px;
        `
      : "";
  }}
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: ${({ theme }) => theme.shadows.elevation1};

  ${({ backgroundColor, theme }) => {
    return css`
      background: ${theme.colors[backgroundColor]};
    `;
  }}
`;

const Rectangle = styled.div<{ rectangleColor: DefaultThemeColor }>`
  width: 2px;
  height: 20px;
  ${({ rectangleColor, theme }) => {
    return css`
      background: ${theme.colors[rectangleColor]};
    `;
  }}
`;

const Text = styled.div<{ color: DefaultThemeColor }>`
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  white-space: nowrap;
  ${({ color, theme }) => {
    return css`
      color: ${theme.colors[color]};
    `;
  }}
`;
