export interface ApiRequest {
  url: string;
  method: string;
  body?: any;
  headers?: Headers;
  abortSignal?: AbortSignal;
}

interface ApiBase {
  get?: (url: string, abortSignal?: AbortSignal) => Promise<any>;
  getRaw?: (url: string, abortSignal?: AbortSignal) => Promise<any>;
  post?: (url: string, body: any, headers?: Headers, abortSignal?: AbortSignal) => Promise<any>;
  delete?: (url: string, abortSignal?: AbortSignal) => Promise<any>;
}

export const api: ApiBase = {
  get: undefined,
  getRaw: undefined,
  post: undefined,
  delete: undefined
};

export const doGet = (url: string, abortSignal?: AbortSignal) => {
  if (!api.get) {
    return Promise.reject();
  }
  return api.get(url, abortSignal);
};

export const doGetRaw = (url: string, abortSignal?: AbortSignal) => {
  if (!api.getRaw) {
    return Promise.reject();
  }
  return api.getRaw(url, abortSignal);
};

export const doPost = (url: string, body?: any, headers?: Headers, abortSignal?: AbortSignal) => {
  if (!api.post) {
    return Promise.reject();
  }
  return api.post(url, body, headers, abortSignal);
};

export const doDelete = (url: string, abortSignal?: AbortSignal) => {
  if (!api.delete) {
    return Promise.reject();
  }
  return api.delete(url, abortSignal);
};
