enum WebmailErrorCode {
  UserCannotBeCreated = 1,
  AccountCannotBeCreated = 2,
  UserNotPermitted = 3,
  WrongDomainInEmail = 4,
  EmptyEmail = 5,
  MalformedEmail = 6,
  TechnicalException = 7
}

export const getWebmailErrorMessage = (errorCode: number) => {
  switch (errorCode) {
    case WebmailErrorCode.UserCannotBeCreated:
    case WebmailErrorCode.AccountCannotBeCreated:
      return "Wystąpił błąd podczas tworzenia konta. Skontaktuj się z help-desk 459-595-100.";
    case WebmailErrorCode.UserNotPermitted:
      return "Brak uprawnień. Skontaktuj się z help-desk 459-595-100.";
    case WebmailErrorCode.WrongDomainInEmail:
    case WebmailErrorCode.EmptyEmail:
    case WebmailErrorCode.MalformedEmail:
      return "Wystąpił błąd w adresie email. Skontaktuj się z help-desk 459-595-100.";
    case WebmailErrorCode.TechnicalException:
      return "System jest niedostępny. Spróbuj ponownie później.";
  }
};
