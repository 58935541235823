import { Modal } from "../../components/Modal/Modal";
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import useAuthService from "../../services/useAuthService";
import styled from "styled-components/macro";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import { useModal } from "react-modal-hook";
import { t } from "../../intl";
import { PeselInput } from "../../components/PruInput/PeselInput";
import { Loader } from "../../components/Loader/Loader";

export interface GenerateAuthorizationCodeModalProps {
  onClose: () => void;
  username: string;
}

export const GenerateAuthorizationCodeModal = (props: GenerateAuthorizationCodeModalProps) => {
  const { generateAuthorizationCode, confirmAuthorizationCode } = useAuthService();
  const [indexesToVerify, setIndexesToVerify] = useState<number[]>([]);
  const [allValues, setAllValues] = useState<string[]>(Array.from(new Array(11)));
  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);
  const [loading, setLoading] = useState(false);

  const [showSuccessfulMessage, hideSuccessfulMessage] = useModal(
    () => (
      <ConfirmModal
        messageTrKey="resetPassword.label.temporaryPasswordHasBeenSent"
        confirmTrKey="button.ok"
        onConfirm={() => {
          hideSuccessfulMessage();
          props.onClose();
        }}
      />
    ),
    []
  );

  const clearValues = (indexesToVerify: number[]) => {
    setAllValues(prevValues =>
      prevValues.map((_, index) => (indexesToVerify.includes(index) ? "" : "X"))
    );
  };

  useEffect(() => {
    setLoading(true);
    generateAuthorizationCode(props.username)
      .then(response => {
        setIndexesToVerify(response);
        clearValues(response);
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  }, [generateAuthorizationCode, props.username]);

  const onChange = (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
    const newValues = [...allValues];
    newValues[index] = event.target.value;
    setAllValues(newValues);
    if (newValues[index].toString().length > 0) {
      focusNext(index + 1);
    }
  };

  const onKeyDown = (index: number) => (event: React.KeyboardEvent) => {
    const backSpaceCode = 8;
    if (event.keyCode === backSpaceCode && allValues[index] === "") {
      focusPrev(index);
    }
  };

  const focusNext = useCallback(
    (startIndex: number) => {
      const nextIndex = indexesToVerify.find(i => i >= startIndex);
      if (nextIndex !== undefined) {
        inputRefs.current[nextIndex]?.focus();
      }
    },
    [indexesToVerify]
  );

  const focusPrev = useCallback(
    (startIndex: number) => {
      const nextIndex = indexesToVerify.filter(i => i < startIndex).sort((a, b) => b - a)[0];
      if (nextIndex !== undefined) {
        inputRefs.current[nextIndex]?.focus();
      }
    },
    [indexesToVerify]
  );

  useEffect(() => {
    focusNext(0);
  }, [focusNext]);

  const allNotEmpty =
    !!indexesToVerify.length && indexesToVerify.every(idx => allValues[idx] !== "");

  const handleSubmit = () => {
    setLoading(true);
    confirmAuthorizationCode(
      props.username,
      allValues.filter(v => v !== "X")
    )
      .finally(() => setLoading(false))
      .then(showSuccessfulMessage)
      .catch(() => {
        clearValues(indexesToVerify);
      });
  };

  return (
    <Modal
      cancelTrKey={"button.cancel"}
      onCancel={props.onClose}
      confirmTrKey={"resetPassword.button.sendSms"}
      confirmDisabled={!allNotEmpty}
      onConfirm={handleSubmit}
    >
      {loading && <Loader />}
      <form autoComplete="off">
        <ContentWrapper>
          <TextHeader>{t("resetPassword.label.enterPeselCharacters")}</TextHeader>
          <Codes>
            {allValues.map((_, index) => (
              <CodeBlock key={index}>
                <CodeInput
                  id={`${index}`}
                  name={`${index}`}
                  value={allValues[index] || ""}
                  onChange={onChange(index)}
                  onKeyDown={onKeyDown(index)}
                  disabled={!indexesToVerify.includes(index)}
                  ref={(e: HTMLInputElement) => (inputRefs.current[index] = e)}
                />
                <CodeLabel>{index + 1}</CodeLabel>
              </CodeBlock>
            ))}
          </Codes>
        </ContentWrapper>
      </form>
    </Modal>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const TextHeader = styled.div`
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: ${({ theme }) => theme.colors.primary100};
`;

const Codes = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const CodeBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CodeInput = styled(PeselInput)`
  width: 32px;
  margin: 0 8px;
  padding-left: 0 !important;
  padding-right: 0 !important;
`;

const CodeLabel = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray100};
`;
