import React, { FC } from "react";
import styled from "styled-components/macro";
import { DefaultThemeColor } from "../../theme/defaultTheme";

export enum IconWeight {
  normal,
  bold
}

export interface IconProps {
  name: string;
  color?: DefaultThemeColor;
  hoverColor?: DefaultThemeColor;
  size?: number;
  onClick?: () => void;
  weight?: IconWeight;
}

export const Icon: FC<IconProps> = (props: IconProps): JSX.Element | null => {
  const { name, color, hoverColor, size, onClick, weight, ...rest } = props;

  return (
    <IconWrapper color={color} onClick={onClick} size={size} hoverColor={hoverColor} {...rest}>
      <i className={`pru-iconset_${name} ${weight === IconWeight.bold && "icon-bold"}`} />
    </IconWrapper>
  );
};

const IconWrapper = styled.div<{
  color?: DefaultThemeColor;
  hoverColor?: DefaultThemeColor;
  size?: number;
  onClick?: () => void;
}>`
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ size }) => size && `font-size: ${size}px;`}
  ${({ color, theme }) => color && `color: ${theme.colors[color]};`}
  ${({ onClick }) => onClick && `cursor: pointer;`}
  &:hover {
    ${({ hoverColor, theme }) => hoverColor && `color: ${theme.colors[hoverColor]};`};
  }
`;
