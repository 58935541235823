import React from "react";
import styled from "styled-components/macro";
import { Button } from "../Button/Button";

interface ModalProps {
  onClose?: () => void;
  cancelTrKey?: string;
  onCancel?: () => void;
  confirmTrKey?: string;
  confirmDisabled?: boolean;
  onConfirm?: () => void;
  children?: React.ReactNode;
  maxWidth?: number;
  zindex?: number;
}

export const Modal = ({
  onClose,
  cancelTrKey,
  onCancel,
  confirmTrKey,
  confirmDisabled,
  onConfirm,
  children,
  maxWidth,
  zindex
}: ModalProps) => {
  return (
    <Mask onClick={onClose} zindex={zindex}>
      <ModalWrapper maxWidth={maxWidth}>
        <ContentWrapper>{children}</ContentWrapper>
        <HorizontalLine />
        <ButtonsWrapper>
          {onCancel && cancelTrKey && (
            <Button buttonType={"secondary"} onClick={onCancel} textTrKey={cancelTrKey} />
          )}
          {onConfirm && confirmTrKey && (
            <Button
              buttonType={"primary"}
              onClick={onConfirm}
              disabled={confirmDisabled}
              textTrKey={confirmTrKey}
            />
          )}
        </ButtonsWrapper>
      </ModalWrapper>
    </Mask>
  );
};

const Mask = styled.div<{ zindex?: number }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.gray100_07};
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${({ zindex }) => (zindex ? zindex : 20)};
`;

const ModalWrapper = styled.div<{ maxWidth?: number }>`
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : 652)}px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray10};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  overflow: auto;
  padding: 32px;
`;

const HorizontalLine = styled.div`
  width: 100%;
  height: 0px;
  border-top: 1px solid ${({ theme }) => theme.colors.gray30};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 16px;
`;
