import React from "react";
import { Icon } from "../PruIcon/Icon";
import styled from "styled-components";

interface HeaderIconProps {
  name: string;
  action?: () => void;
}

export const HeaderIcon = ({ name, action }: HeaderIconProps) => {
  return (
    <IconWrapper>
      <Icon name={name} onClick={action} size={32} />
    </IconWrapper>
  );
};

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  background: ${({ theme }) => theme.colors.gray10};
  border-radius: 24px;
  cursor: pointer;

  :hover {
    background: ${({ theme }) => theme.colors.gray40};
  }
`;
