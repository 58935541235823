import { Input, InputProps } from "./Input";
import React from "react";
import styled from "styled-components/macro";
import { Label, LabelProps } from "../Label/Label";
import { ValidationInfo, ValidationInfoProps } from "../ValidationInfo/ValidationInfo";

export type FormInputProps = Omit<InputProps, "disabled" | "isInvalid" | "hasDarkBackground">;
export interface InputFormElementProps {
  inputProps: FormInputProps;
  labelProps?: Omit<LabelProps, "disabled">;
  validationProps?: ValidationInfoProps;
  disabled?: boolean;
  isInvalid?: boolean;
  hasDarkBackground?: boolean;
}

export const InputFormElement = ({
  inputProps,
  labelProps,
  validationProps,
  isInvalid,
  disabled,
  hasDarkBackground
}: InputFormElementProps) => {
  return (
    <Wrapper>
      {labelProps && <Label {...labelProps} disabled={disabled} />}
      <Input
        {...inputProps}
        disabled={disabled}
        isInvalid={isInvalid}
        hasDarkBackground={hasDarkBackground}
      />
      {validationProps && (
        <ValidationWrapper>
          {isInvalid && (
            <ValidationInfo
              {...validationProps}
              withIcon={hasDarkBackground}
              textColor={hasDarkBackground ? "white" : "error"}
            />
          )}
        </ValidationWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ValidationWrapper = styled.div`
  min-height: 20px;
`;
