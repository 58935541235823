import { NavigateFunction } from "react-router-dom";

export enum RoutePath {
  home = "/",
  apps = "/apps",
  mfa = "/mfa",
  login = "/login",
  passwordExpired = "/password-expired",
  passwordChange = "/password-change",
  notifications = "/notifications"
}

export const navigator: { navigate?: NavigateFunction } = {
  navigate: undefined
};

export const redirect = (to: RoutePath, params?: object) => {
  if (!navigator.navigate) {
    return;
  }
  const routingParams = { fromApp: true, ...params };
  navigator.navigate(to, { replace: true, state: { ...routingParams } });
};
