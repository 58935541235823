import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadingStatus } from "../constatns/LoadingStatus";
import { Notification, notificationFieldNames } from "../models/Notification";
import { getNotifications } from "../services/NotificationService";
import { GridState, SortDescriptor } from "../models/GridState";
import { Status } from "../constatns/Notification/Status";
import { Priority } from "../constatns/Notification/Priority";
import { RootState } from "../store";
import { getSortState } from "../components/Grid/sort";

export interface NotificationState {
  loadingStatus: LoadingStatus;
  notifications: Notification[];
  total: number;
  totalUnread: number;
  totalBlocking: number;
  gridState: GridState;
  blockingNotifications: boolean;
}

const initialState: NotificationState = {
  loadingStatus: LoadingStatus.Unloaded,
  notifications: [],
  total: 0,
  totalUnread: 0,
  totalBlocking: 0,
  gridState: {
    skip: 0,
    take: 10,
    sort: [
      { field: notificationFieldNames.status, dir: "asc" },
      { field: notificationFieldNames.publishDate, dir: "desc" }
    ]
  },
  blockingNotifications: false
};

const blockingNotificationState: GridState = {
  skip: 0,
  take: 30,
  sort: [
    { field: notificationFieldNames.status, dir: "asc" },
    { field: notificationFieldNames.priority, dir: "asc" },
    { field: notificationFieldNames.publishDate, dir: "desc" }
  ],
  filter: {
    logic: "and",
    filters: [
      {
        field: notificationFieldNames.status,
        operator: "eq",
        value: Status.unread
      },
      {
        logic: "or",
        filters: [
          {
            field: notificationFieldNames.priority,
            operator: "eq",
            value: Priority.urgent
          },
          {
            field: notificationFieldNames.priority,
            operator: "eq",
            value: Priority.high
          }
        ]
      }
    ]
  }
};

const getSearchKeywordNotificationState = (keyword: string): GridState => {
  return {
    skip: initialState.gridState.skip,
    take: initialState.gridState.take,
    sort: initialState.gridState.sort,
    filter: {
      logic: "or",
      filters: [
        {
          field: "content",
          operator: "contains",
          value: keyword
        },
        {
          field: notificationFieldNames.title,
          operator: "contains",
          value: keyword
        }
      ]
    }
  };
};

const fetchNotifications = createAsyncThunk("notifications/fetch", async (_, thunkAPI) => {
  const { notifications } = thunkAPI.getState() as RootState;
  return getNotifications(notifications.gridState, thunkAPI.signal);
});

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    markRead: (
      state,
      action: PayloadAction<{ notificationId: number; updatedNotification: Notification }>
    ) => {
      const { notificationId, updatedNotification } = action.payload;
      const notification = state.notifications.find(item => item.id === notificationId);
      if (notification) {
        notification.status = Status.read;
        notification.readDate = updatedNotification.readDate;
      }
      state.totalUnread = state.totalUnread > 0 ? state.totalUnread - 1 : 0;
    },
    postpone: state => {
      state.totalBlocking = 0;
    },
    setBlockingNotificationFilter: state => {
      return { ...initialState, blockingNotifications: true, gridState: blockingNotificationState };
    },
    setSearchKeywordNotificationFilter: (state, action: PayloadAction<string>) => {
      return { ...initialState, gridState: getSearchKeywordNotificationState(action.payload) };
    },
    resetNotifications: state => {
      return { ...initialState };
    },
    setSortGridState: (state, action: PayloadAction<SortDescriptor>) => {
      return {
        ...state,
        gridState: {
          ...state.gridState,
          sort: getSortState(action.payload, state.gridState.sort)
        }
      };
    },
    setSkipGridState: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        gridState: {
          ...state.gridState,
          skip: action.payload
        }
      };
    },
    setTakeGridState: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        gridState: {
          ...state.gridState,
          take: action.payload
        }
      };
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchNotifications.pending, state => {
      return {
        ...state,
        notifications: [],
        total: 0,
        totalUnread: 0,
        totalBlocking: 0,
        loadingStatus: LoadingStatus.InProgress
      };
    });
    builder.addCase(fetchNotifications.fulfilled, (state, action) => {
      return {
        ...state,
        notifications: action.payload.items,
        total: action.payload.total,
        totalUnread: action.payload.totalUnread,
        totalBlocking: action.payload.totalBlocking,
        loadingStatus: LoadingStatus.Loaded
      };
    });
    builder.addCase(fetchNotifications.rejected, state => {
      return {
        ...state,
        notifications: [],
        total: 0,
        totalUnread: 0,
        totalBlocking: 0,
        loadingStatus: LoadingStatus.Failed
      };
    });
  }
});

export const notificationsActions = {
  ...notificationsSlice.actions,
  fetchNotifications
};
export const notificationsReducer = notificationsSlice.reducer;
