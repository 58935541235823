import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { t } from "../../intl";
import { RoutePath } from "../../utils/redirect";

const getCurrentText = (path: string) => {
  switch (path) {
    case RoutePath.notifications: {
      return t("currentRoute.notifications");
    }
    case RoutePath.apps: {
      return t("currentRoute.apps");
    }
  }
  return "";
};

export const CurrentLocation = () => {
  const location = useLocation();
  const currentText = getCurrentText(location.pathname);
  return <Wrapper>{currentText}</Wrapper>;
};

const Wrapper = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray100};
`;
