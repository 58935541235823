import React from "react";
import styled from "styled-components/macro";
import { AvailableApp } from "../../../models/AvailableApp";

interface TileProps {
  tile: AvailableApp;
}

export const Tile = ({ tile }: TileProps) => {
  const getApplicationUrl = () => {
    const { location } = window;
    const { link } = tile;
    return link.includes("http") ? link : `${location.protocol}//${location.host}${link}`;
  };

  return (
    <TileLink href={getApplicationUrl()} target="_blank" rel="noopener noreferrer">
      <Wrapper>
        <AppNameWrapper>
          <AppName>{tile.name}</AppName>
          {tile.tooltip && <SubAppName>{tile.tooltip}</SubAppName>}
        </AppNameWrapper>
        <ShortName>{tile.shortName}</ShortName>
      </Wrapper>
    </TileLink>
  );
};

const TileLink = styled.a`
  text-decoration: none;
  border: none;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  width: 192px;
  height: 200px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray30};
  box-shadow: ${({ theme }) => theme.shadows.elevation2};
  border-radius: 4px;

  transition-duration: unset;
  transition-timing-function: unset;

  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.secondary100};
    box-shadow: none;
  }
`;

const AppNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const AppName = styled.span`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.gray100};
`;

const SubAppName = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray60};
`;

const ShortName = styled.span`
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.secondary100};
`;
