import React, { useEffect, useState } from "react";
import PageLayout from "../../layouts/PageLayout";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { LoadingStatus } from "../../constatns/LoadingStatus";
import { notificationsActions } from "../../slices/notificationsSlice";
import { Notification, notificationFieldNames } from "../../models/Notification";
import { t } from "../../intl";
import { NotificationContent } from "../../components/Notification/NotificationContent";
import { markAsRead, postpone } from "../../thunks/notificationThunk";
import { headerBelowLgLeftRightMargin, headerLeftRightMargin } from "../../constatns/layout";
import { Button } from "../../components/Button/Button";
import styled from "styled-components";
import { Input } from "../../components/PruInput/Input";
import { redirect, RoutePath } from "../../utils/redirect";
import { SortDir } from "../../models/GridState";
import { Grid } from "../../components/Grid/Grid";
import { notificationColumns } from "./notificationColumns";

interface NotificationListPageProps {
  setSpinnerVisible: (isVisible: boolean) => void;
}

export const NotificationListPage = ({ setSpinnerVisible }: NotificationListPageProps) => {
  const dispatch = useDispatch();
  const [selectedNotification, setSelectedNotification] = useState<Notification>();
  const [keyword, setKeyword] = useState<string>("");
  const [filterValue, setFilterValue] = useState<string>("");
  const { notifications, loadingStatus, gridState, total } = useSelector(
    (state: RootState) => state.notifications
  );

  useEffect(() => {
    if (loadingStatus === LoadingStatus.Unloaded) {
      fetchNotifications();
    }
  }, [loadingStatus]);

  const fetchNotifications = () => {
    (async () => {
      setSpinnerVisible(true);
      dispatch(notificationsActions.resetNotifications());
      await dispatch(notificationsActions.fetchNotifications());
      setSelectedNotification(undefined);
      setSpinnerVisible(false);
    })();
  };

  const handleMarkAsRead = (notificationId: number) => {
    (async () => {
      setSpinnerVisible(true);
      await dispatch(markAsRead(notificationId));
      setSelectedNotification(undefined);
      setSpinnerVisible(false);
    })();
  };
  const handlePostpone = () => {
    (async () => {
      setSpinnerVisible(true);
      await dispatch(postpone());
      setSelectedNotification(undefined);
      setSpinnerVisible(false);
    })();
  };
  const handleOnClose = () => {
    setSelectedNotification(undefined);
  };

  const handleSearch = () => {
    (async () => {
      setSpinnerVisible(true);
      dispatch(notificationsActions.setSearchKeywordNotificationFilter(keyword));
      await dispatch(notificationsActions.fetchNotifications());
      setFilterValue(keyword);
      setKeyword("");
      setSpinnerVisible(false);
    })();
  };

  const backToApps = () => {
    redirect(RoutePath.apps);
  };

  const handleSortChanged = (fieldName: string, dir: SortDir) => {
    (async () => {
      setSpinnerVisible(true);
      dispatch(notificationsActions.setSortGridState({ field: fieldName, dir: dir }));
      await dispatch(notificationsActions.fetchNotifications());
      setSpinnerVisible(false);
    })();
  };

  const handleSkipChanged = (skip: number) => {
    (async () => {
      setSpinnerVisible(true);
      dispatch(notificationsActions.setSkipGridState(skip));
      await dispatch(notificationsActions.fetchNotifications());
      setSpinnerVisible(false);
    })();
  };

  const clearFilterValue = () => {
    setFilterValue("");
    fetchNotifications();
  };

  return (
    <PageLayout>
      <>
        {selectedNotification && (
          <NotificationContent
            notification={selectedNotification}
            onMarkAsRead={handleMarkAsRead}
            onPostpone={handlePostpone}
            onClose={handleOnClose}
          />
        )}
        {loadingStatus === LoadingStatus.Loaded && !selectedNotification && (
          <Wrapper>
            <ListWrapper>
              <SearchBar>
                <NameSection>{t("notification.list.label")}</NameSection>
                <SearchSection>
                  <SearchInput
                    value={keyword}
                    onValueChange={setKeyword}
                    onEnter={handleSearch}
                    placeholderTrKey={"notification.list.searchPlaceholder"}
                    autoFocus={true}
                    showClear={true}
                    iconName={"search"}
                    iconPosition={"left"}
                  />
                  <Button
                    buttonType={"primary"}
                    onClick={handleSearch}
                    textTrKey={"notification.button.search"}
                  />
                </SearchSection>
              </SearchBar>
              {!!filterValue && (
                <FilterInfoContainer>
                  <FilterDescription>
                    {notifications.length === 0
                      ? t("notification.list.filterNotificationTitleEmpty")
                      : t(`notification.list.filterNotificationTitleFind`, {
                          notificationLength: notifications.length
                        })}
                  </FilterDescription>{" "}
                  <FilterValue>"{filterValue}"</FilterValue>
                  <Button
                    onClick={clearFilterValue}
                    buttonSize={"sm"}
                    buttonType={"secondary"}
                    textTrKey={"notification.button.clearFilter"}
                  />
                </FilterInfoContainer>
              )}
              {notifications.length !== 0 && (
                <Grid
                  columns={notificationColumns}
                  items={notifications}
                  idName={notificationFieldNames.id}
                  gridState={gridState}
                  total={total}
                  onSortChanged={handleSortChanged}
                  onSkipChanged={handleSkipChanged}
                  onItemSelected={setSelectedNotification}
                />
              )}
            </ListWrapper>
            {notifications.length === 0 && (
              <NotificationEmptyDescWrapper>
                <NotificationEmptyDesc>
                  {t(`notification.list.EmptyNotificationList`)}
                </NotificationEmptyDesc>
              </NotificationEmptyDescWrapper>
            )}
            <ActionBar>
              <Button
                onClick={backToApps}
                buttonType={"secondary"}
                leftIconName={"chevron-left"}
                textTrKey={"button.back"}
              />
            </ActionBar>
          </Wrapper>
        )}
      </>
    </PageLayout>
  );
};

const NotificationEmptyDescWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NotificationEmptyDesc = styled.p`
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  color: ${({ theme }) => theme.colors.gray80};
`;

const FilterInfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;
`;

const FilterValue = styled.span`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin: 0 12px;
  color: ${({ theme }) => theme.colors.primary100};
`;

const FilterDescription = styled.span`
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray80};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  min-height: 100%;
  background: ${({ theme }) => theme.colors.gray10};
`;

const ListWrapper = styled.div`
  padding: 48px ${headerLeftRightMargin}px;

  @media (max-height: 960px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  ${({ theme }) => theme.media.belowLg} {
    padding-left: ${headerBelowLgLeftRightMargin}px;
    padding-right: ${headerBelowLgLeftRightMargin}px;
  }
  overflow: auto;
`;

const SearchBar = styled.div`
  height: 76px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  @media (max-height: 960px) {
    padding-bottom: 16px;
  }
`;

const NameSection = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
`;

const SearchSection = styled.div`
  height: 52px;
  display: flex;
  flex-direction: row;
`;

const SearchInput = styled(Input)`
  height: 52px;
  width: 334px;
`;

const ActionBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  height: 80px;
  padding: 0px 48px;
`;
