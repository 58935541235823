import React, { FormEvent, useContext, useEffect } from "react";
import PageLayout from "../../layouts/PageLayout";
import useAuthService from "../../services/useAuthService";
import { FormProvider, useForm } from "react-hook-form";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import { useModal } from "react-modal-hook";
import { AuthContext } from "../../App";
import { useLocation } from "react-router-dom";
import { GenerateAuthorizationCodeModal } from "./GenerateAuthorizationCodeModal";
import {
  NewPasswordForm,
  NewPasswordFormValues
} from "../../components/NewPasswordForm/NewPasswordForm";
import { AuthErrorCode } from "../../constatns/AuthErrorCode";
import { NewPasswordFieldNames } from "../../components/NewPasswordForm/NewPasswordFieldNames";
import { ResetPasswordFieldNames } from "./ResetPasswordFieldNames";
import { redirect, RoutePath } from "../../utils/redirect";
import resetPasswordIcon from "../../assets/logout.svg";
import separator from "../../assets/separator.svg";

import { t } from "../../intl";
import { LinkButton } from "../../components/LinkButton/LinkButton";
import { UseFormTrigger } from "react-hook-form/dist/types/form";
import { Button } from "../../components/Button/Button";
import styled from "styled-components";
import { InputReactHookFormAdapter } from "../../components/PruInput/InputReactHookFormAdapter";

export interface ResetPasswordFormValues extends NewPasswordFormValues {
  [ResetPasswordFieldNames.username]: string;
  [ResetPasswordFieldNames.authorizationCode]: string;
}

export interface ChangePasswordPageProps {
  setSpinnerVisible: (isVisible: boolean) => void;
}

export const ResetPasswordPage = (props: ChangePasswordPageProps) => {
  const { resetPassword } = useAuthService();
  const authContext = useContext(AuthContext);
  const { state } = useLocation();

  const formMethods = useForm<ResetPasswordFormValues>({
    mode: "onChange",
    reValidateMode: "onChange",
    criteriaMode: "all",
    defaultValues: {
      username: authContext.username
    }
  });
  const { register, watch, trigger, setValue, formState, setFocus } = formMethods;

  useEffect(() => {
    setFocus(ResetPasswordFieldNames.username);
  }, [state, setFocus]);

  const data = watch();

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    await trigger(ResetPasswordFieldNames.username);
    await trigger(ResetPasswordFieldNames.authorizationCode);
    await trigger(NewPasswordFieldNames.newPassword);
    await trigger(NewPasswordFieldNames.newPasswordRepeated);
    if (!formState.isValid) {
      return;
    }
    props.setSpinnerVisible(true);
    resetPassword(data.username, data.authorizationCode, data.newPassword)
      .then(showSuccessfulMessage)
      .catch(error => {
        if (error?.errorCode === AuthErrorCode.INVALID_PASSWORD_ERROR_CODE) {
          resetNewPasswords();
        }
      })
      .finally(() => props.setSpinnerVisible(false));
  };

  const resetNewPasswords = () => {
    setValue(NewPasswordFieldNames.newPassword, "");
    setValue(NewPasswordFieldNames.newPasswordRepeated, "");
  };

  const [showSuccessfulMessage, hideSuccessfulMessage] = useModal(
    () => (
      <ConfirmModal
        messageTrKey={"resetPassword.label.passwordHasBeenChanged"}
        confirmTrKey={"button.ok"}
        onConfirm={() => {
          hideSuccessfulMessage();
          redirect(RoutePath.login);
        }}
      />
    ),
    []
  );

  const [showGenerateAuthorizationCode, hideGenerateAuthorizationCode] = useModal(
    () => (
      <GenerateAuthorizationCodeModal
        onClose={hideGenerateAuthorizationCode}
        username={data.username}
      />
    ),
    [data.username]
  );

  const redirectToLogin = () => redirect(RoutePath.login);

  return (
    <PageLayout logoutVisible={false}>
      <Wrapper>
        <ResetPasswordHeader>
          <ResetPasswordIcon>
            <img src={resetPasswordIcon} alt={"Reset password"} />
          </ResetPasswordIcon>
          <HeaderText>{t("resetPassword.header")}</HeaderText>
          <HeaderSubText>{t("resetPassword.subHeader")}</HeaderSubText>
        </ResetPasswordHeader>

        <FormProvider {...formMethods}>
          <Form onSubmit={onSubmit}>
            <FormInputs>
              <TempPasswordForm>
                <InputReactHookFormAdapter
                  labelProps={{
                    labelTrKey: "resetPassword.input.login.label"
                  }}
                  registerProps={register(ResetPasswordFieldNames.username, {
                    required: true
                  })}
                  inputProps={{
                    value: data.username,
                    placeholderTrKey: "resetPassword.input.login.placeholder",
                    autoComplete: "off"
                  }}
                  isInvalid={!!formState.errors[ResetPasswordFieldNames.username]?.types}
                  setValue={setValue}
                />
                <LinkButton
                  textTrKey={"resetPassword.button.sendSmsWithTemporaryPassword"}
                  onClick={showGenerateAuthorizationCode}
                  disabled={!data.username?.length}
                />
                <TypeTemporaryPasswordText>
                  {t("resetPassword.label.enterTemporaryPassword")}
                </TypeTemporaryPasswordText>
                <InputReactHookFormAdapter
                  registerProps={register(ResetPasswordFieldNames.authorizationCode, {
                    required: true
                  })}
                  inputProps={{
                    value: data.authorizationCode,
                    placeholderTrKey: "resetPassword.input.temporaryPassword.placeholder",
                    autoComplete: "off",
                    inputType: "password"
                  }}
                  isInvalid={!!formState.errors[ResetPasswordFieldNames.authorizationCode]?.types}
                  setValue={setValue}
                />
              </TempPasswordForm>
              <img src={separator} alt={"separator"} />
              <NewPasswordForm
                errors={formState.errors}
                trigger={trigger as unknown as UseFormTrigger<NewPasswordFormValues>}
              />
            </FormInputs>
            <FormButtons>
              <Button
                buttonType={"secondary"}
                onClick={redirectToLogin}
                leftIconName={"arrow-left"}
                textTrKey={"button.cancel"}
              />
              <Button
                buttonType={"primary"}
                type={"submit"}
                disabled={!formState.isValid}
                textTrKey={"button.next"}
              />
            </FormButtons>
          </Form>
        </FormProvider>
      </Wrapper>
    </PageLayout>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  padding: 48px;
`;

const ResetPasswordHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
`;

const ResetPasswordIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  height: 80px;
  width: 80px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  img {
    width: 100%;
  }
`;

const HeaderText = styled.div`
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: ${({ theme }) => theme.colors.gray100};
`;

const HeaderSubText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray100};
`;

const Form = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
`;

const FormInputs = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
  padding: 32px;
  background: ${({ theme }) => theme.colors.white};
`;

const TempPasswordForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 344px;
`;

const TypeTemporaryPasswordText = styled.div`
  display: flex;
  align-self: flex-start;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.gray100};
`;

const FormButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;
