import { doGetRaw, doPost } from "./ApiService";
import { Notification } from "../models/Notification";
import { buildQueryString } from "../utils/buildQueryString";
import { GridState } from "../models/GridState";
import { NotificationsDto } from "../models/dto/NotificationsDto";

const buildBlockingNotificationQueryString = (gridState: GridState): string => {
  const queryString = buildQueryString(gridState);
  return queryString ? `?${queryString}` : "";
};

export const getNotifications = (
  gridState: GridState,
  abortSignal?: AbortSignal
): Promise<NotificationsDto> =>
  doGetRaw(
    `/home/api/notifications${buildBlockingNotificationQueryString(gridState)}`,
    abortSignal
  );

export const postponeNotification = () => doPost(`/home/api/notifications/postpone`);

export const markReadNotification = (notificationId: number): Promise<Notification> =>
  doPost(`/home/api/notifications/${notificationId}/mark-read`);
