import React, { useCallback } from "react";
import styled from "styled-components/macro";
import { Status } from "../../constatns/Notification/Status";
import { Priority } from "../../constatns/Notification/Priority";
import { t } from "../../intl";
import { Notification } from "../../models/Notification";
import { headerBelowLgLeftRightMargin, headerLeftRightMargin } from "../../constatns/layout";
import { Button } from "../Button/Button";
import { NotificationPriority } from "./NotificationPriority";
import { useDispatch, useSelector } from "react-redux";
import { markAsRead } from "../../thunks/notificationThunk";
import { NotificationStatus } from "./NotificationStatus";
import { Warning } from "../Warning/Warning";
import { RootState } from "../../store";

interface NotificationContentProps {
  notification: Notification;
  onMarkAsRead?: (notificationId: number) => void;
  onPostpone?: () => void;
  onClose?: () => void;
}

export const NotificationContent = ({
  notification,
  onMarkAsRead,
  onPostpone,
  onClose
}: NotificationContentProps) => {
  const { contentGuid, id, priority, status, publishDate, title } = notification;
  const dispatch = useDispatch();
  const { totalBlocking } = useSelector((state: RootState) => state.notifications);
  const postponed = priority === Priority.high && status === Status.unread && totalBlocking === 0;
  const showMarkAsReadButton = (status: Status, priority: Priority) =>
    (status === Status.unread || postponed) &&
    (priority === Priority.urgent || priority === Priority.high);
  const showPostponeButton =
    status === Status.unread && priority === Priority.high && totalBlocking > 0;
  const showCancelButton = (status: Status, priority: Priority) =>
    status === Status.read || priority === Priority.info;
  const iframeSrc = `/home/api/notification-preview/${contentGuid}/`;

  const handleMarkAsRead = useCallback(() => {
    (async () => {
      if (notification.status === Status.unread && notification.priority === Priority.info) {
        await dispatch(markAsRead(id));
      }
    })();
  }, [id]);

  return (
    <Wrapper>
      <MessageWrapper>
        {totalBlocking > 0 &&
          notification.status === Status.unread &&
          notification.priority === Priority.urgent && (
            <WarningWrapper>
              <Warning messageTrKey={"notification.label.unreadUrgentWarning"} />
            </WarningWrapper>
          )}
        <InfoBar>
          <TitleSection>
            <Label>{t("notification.label.title")}</Label>
            <Title>{title}</Title>
          </TitleSection>
          <SectionGroup>
            <Separator />
            <DateSection>
              <Label>{t("notification.label.publishDate")}</Label>
              <PublishDate>{publishDate}</PublishDate>
            </DateSection>
            <Separator />
            <PrioritySection>
              <Label>{t("notification.label.priority")}</Label>
              <NotificationPriority priority={priority} />
            </PrioritySection>
            <Separator />
            <PrioritySection>
              <Label>{t("notification.label.status")}</Label>
              <NotificationStatus status={status} />
            </PrioritySection>
          </SectionGroup>
        </InfoBar>
        <Content>
          <iframe src={iframeSrc} onLoad={handleMarkAsRead} />
        </Content>
        <ActionBar>
          {onClose && showCancelButton(status, priority) && (
            <Button
              buttonType={"primary"}
              onClick={() => onClose()}
              textTrKey={"notification.button.close"}
            />
          )}
          {onPostpone && showPostponeButton && (
            <Button
              buttonType={"secondary"}
              onClick={onPostpone}
              textTrKey={"notification.button.postpone"}
              leftIconName={"bell-off"}
            />
          )}
          {onMarkAsRead && showMarkAsReadButton(status, priority) && (
            <Button
              buttonType={"primary"}
              onClick={() => onMarkAsRead(id)}
              textTrKey={"notification.button.markAsRead"}
            />
          )}
        </ActionBar>
      </MessageWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
`;

const MessageWrapper = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
`;

const WarningWrapper = styled.div`
  width: 100%;
  padding: 24px ${headerLeftRightMargin}px;

  ${({ theme }) => theme.media.belowLg} {
    padding: 24px ${headerBelowLgLeftRightMargin}px;
  }
`;

const InfoBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
  background: ${({ theme }) => theme.colors.gray10};
  border: 1px solid ${({ theme }) => theme.colors.gray30};
  padding: 0px ${headerLeftRightMargin}px;

  ${({ theme }) => theme.media.belowLg} {
    padding: 0px ${headerBelowLgLeftRightMargin}px;
  }
`;

const Separator = styled.div`
  border-left: 1px solid #d1d1d1;
`;

const Label = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray80};
`;

const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
  max-width: 100%;
  gap: 4px;
  max-width: 65%;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.gray100};
  word-break: break-word;
`;

const SectionGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 35%;
`;

const DateSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 8px;
  gap: 4px;
`;

const PublishDate = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray100};
`;

const PrioritySection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
  gap: 4px;
`;

const contentMarginTop = 20;
const Content = styled.div`
  width: 100%;
  height: 100%;
  max-width: 999px;
  padding-top: ${contentMarginTop}px;

  > iframe {
    border: none;
    height: 100%;
    width: 100%;
  }
`;

const ActionBar = styled.div`
  width: 100%;
  min-height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 24px;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.gray30};
  padding: 0px ${headerLeftRightMargin}px;
`;
