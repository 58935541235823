import React, { useContext, useEffect, useState } from "react";
import { NotificationContent } from "./NotificationContent";
import { Priority } from "../../constatns/Notification/Priority";
import { Status } from "../../constatns/Notification/Status";
import { LoadingStatus } from "../../constatns/LoadingStatus";
import { markAsRead, postpone } from "../../thunks/notificationThunk";
import { Permission } from "../../constatns/Permission";
import { notificationsActions } from "../../slices/notificationsSlice";
import { Notification } from "../../models/Notification";
import { useDispatch, useSelector } from "react-redux";
import { EnvironmentContext } from "../../App";
import { RootState } from "../../store";
import { Environment } from "../../constatns/Environment";

interface BlockingNotificationsProps {
  setSpinnerVisible: (isVisible: boolean) => void;
  allBlockingRead: () => void;
}

export const BlockingNotifications = ({
  setSpinnerVisible,
  allBlockingRead
}: BlockingNotificationsProps) => {
  const dispatch = useDispatch();
  const environmentContext = useContext(EnvironmentContext);
  const notifications = useSelector((state: RootState) => state.notifications);
  const userInfo = useSelector((state: RootState) => state.userInfo);
  const [currentNotification, setCurrentNotification] = useState<Notification | undefined>(
    undefined
  );

  const blockingNotificationsLoaded =
    notifications.loadingStatus === LoadingStatus.Loaded && notifications.blockingNotifications;

  const canFetchNotifications =
    environmentContext.environment === Environment.AP &&
    userInfo.loadingStatus === LoadingStatus.Loaded &&
    userInfo.permissions.find(permission => permission === Permission.AGENT_NOTIFICATIONS_FE) !==
      undefined;

  const getBlockingNotifications = () => {
    (async () => {
      setSpinnerVisible(true);
      dispatch(notificationsActions.setBlockingNotificationFilter());
      await dispatch(notificationsActions.fetchNotifications());
      setSpinnerVisible(false);
    })();
  };

  useEffect(() => {
    if (canFetchNotifications) {
      getBlockingNotifications();
    } else {
      allBlockingRead();
    }
  }, []);

  const updateCurrentFromNext = (omitNotificationId: number | undefined) => {
    const notification = notifications.totalBlocking
      ? notifications.notifications.find(
          notification =>
            (notification.priority === Priority.urgent ||
              notification.priority === Priority.high) &&
            notification.status === Status.unread &&
            (omitNotificationId ? notification.id !== omitNotificationId : true)
        )
      : undefined;
    setCurrentNotification(notification);
    if (!notification) {
      allBlockingRead();
    }
  };

  useEffect(() => {
    if (blockingNotificationsLoaded) {
      updateCurrentFromNext(undefined);
    }
  }, [notifications]);

  const handleMarkAsRead = (notificationId: number) => {
    (async () => {
      setSpinnerVisible(true);
      await dispatch(markAsRead(notificationId));
      updateCurrentFromNext(notificationId);
      setSpinnerVisible(false);
    })();
  };

  const handlePostpone = (notificationId: number) => {
    (async () => {
      setSpinnerVisible(true);
      await dispatch(postpone());
      updateCurrentFromNext(notificationId);
      setSpinnerVisible(false);
    })();
  };

  return (
    <>
      {currentNotification && (
        <NotificationContent
          notification={currentNotification}
          onMarkAsRead={handleMarkAsRead}
          onPostpone={() => handlePostpone(currentNotification?.id)}
        />
      )}
    </>
  );
};
