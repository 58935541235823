import React, { FC, useContext, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ChangeExpiredPasswordPage } from "./pages/ChangeExpiredPasswordPage/ChangeExpiredPasswordPage";
import { LoginPage } from "./pages/LoginPage/LoginPage";
import { MfaPage } from "./pages/MfaPage/MfaPage";
import { TilesPage } from "./pages/TilesPage/TilesPage";
import useAuthService from "./services/useAuthService";
import { ResetPasswordPage } from "./pages/ResetPasswordPage/ResetPasswordPage";
import { EnvironmentContext } from "./App";
import useEnvironmentService from "./services/useEnvironmentService";
import { Environment } from "./constatns/Environment";
import { WebServiceError } from "./components/WebServiceError/WebServiceError";
import { useDispatch } from "react-redux";
import { NotificationListPage } from "./pages/NotificationListPage/NotificationListPage";
import { userInfoActions } from "./slices/userInfoSlice";
import { Loader } from "./components/Loader/Loader";

export interface AppError {
  app: string;
  errorCode: string;
}

const AppRoutes: FC = () => {
  const { checkAuthentication } = useAuthService();
  const { getEnvironment } = useEnvironmentService();
  const [userAuthorized, setUserAuthorized] = useState(false);
  const [spinnerVisible, setSpinnerVisible] = useState(true);
  const [isAppReady, setIsAppReady] = useState(false);
  const [error, setError] = useState<AppError | null>(null);
  const { environment, setEnvironment } = useContext(EnvironmentContext);
  const dispatch = useDispatch();
  useEffect(() => {
    if (window.location.pathname.startsWith("/home/error/")) {
      //TODO: refactor after adding Redux
      const [app, errorCode] = window.location.pathname.replace("/home/error/", "").split("/");
      setError({ app, errorCode });
    }

    const environmentPromise = getEnvironment().then((env: Environment) => {
      setEnvironment(env);
    });

    const authenticationPromise = checkAuthentication()
      .then(() => {
        setUserAuthorized(true);
      })
      .then(() => dispatch(userInfoActions.fetchUserInfo()))
      .finally(() => {
        setIsAppReady(true);
      });

    Promise.all([environmentPromise, authenticationPromise]).finally(() => {
      setSpinnerVisible(false);
    });
  }, [checkAuthentication, getEnvironment, setEnvironment]);

  const isApEnvironment = environment === Environment.AP;

  return (
    <>
      {spinnerVisible && <Loader />}
      {isAppReady && environment !== undefined && (
        <Routes>
          <Route
            path="/apps"
            element={
              <>
                <TilesPage setSpinnerVisible={setSpinnerVisible} />
                <WebServiceError app={error?.app || ""} errorCode={error?.errorCode || ""} />
              </>
            }
          />

          {!userAuthorized && (
            <>
              <Route path="/login" element={<LoginPage setSpinnerVisible={setSpinnerVisible} />} />
              <Route path="/mfa" element={<MfaPage setSpinnerVisible={setSpinnerVisible} />} />
            </>
          )}
          {isApEnvironment && (
            <>
              <Route
                path="/password-expired"
                element={<ChangeExpiredPasswordPage setSpinnerVisible={setSpinnerVisible} />}
              />
              <Route
                path="/password-change"
                element={<ResetPasswordPage setSpinnerVisible={setSpinnerVisible} />}
              />
              <Route
                path="/notifications"
                element={<NotificationListPage setSpinnerVisible={setSpinnerVisible} />}
              />
            </>
          )}
          <Route path="*" element={<Navigate to={"/apps"} replace />} />
        </Routes>
      )}
    </>
  );
};

export default AppRoutes;
