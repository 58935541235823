import { AuthErrorCode } from "../constatns/AuthErrorCode";

export const resolveAuthenticationErrorTrKey = (code: string): string | undefined => {
  switch (code) {
    case AuthErrorCode.SESSION_EXPIRED_ERROR_CODE:
      return "api.sessionExpired";
    case AuthErrorCode.NOT_FOUND_ERROR_CODE:
      return "api.notFound";
    case AuthErrorCode.INVALID_PASSWORD_ERROR_CODE:
      return "api.invalidPassword";
    case AuthErrorCode.ATTRIBUTE_NOT_FOUND_ERROR_CODE:
      return "api.attributeNotFound";
    case AuthErrorCode.SEND_NOTIFICATION_ERROR_CODE:
      return "api.sendNotificationError";
    case AuthErrorCode.NOTIFICATION_THRESHOLD_EXCEEDED_ERROR_CODE:
      return "api.notificationThresholdExceeded";
    case AuthErrorCode.INVALID_AUTHORIZATION_CODE_ERROR_CODE:
      return "api.invalidAuthorizationCode";
    case AuthErrorCode.NOTIFICATION_CODE_EXPIRED_ERROR_CODE:
      return "api.notificationCodeExpired";
    case AuthErrorCode.INVALID_LOGIN_ERROR_CODE:
      return "api.invalidLogin";
    case AuthErrorCode.PASSWORD_ENFORCE_HISTORY_ERROR_CODE:
      return "api.passwordEnforceHistory";
    case AuthErrorCode.INVALID_CODE_USAGE_EXCEEDED_ERROR_CODE:
      return "api.invalidCodeUsageExceeded";
  }

  return undefined;
};
