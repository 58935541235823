import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { UserInfo } from "../models/UserInfo";
import { LoadingStatus } from "../constatns/LoadingStatus";
import { getUserInfo } from "../services/UserInfoService";

export interface UserInfoState extends UserInfo {
  loadingStatus: LoadingStatus;
}

const initialState: UserInfoState = {
  loadingStatus: LoadingStatus.Unloaded,
  name: "",
  username: "",
  permissions: []
};

export const fetchUserInfo = createAsyncThunk("userInfo/fetch", async (_, thunkAPI) => {
  return getUserInfo(thunkAPI.signal);
});

export const userInfoSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchUserInfo.pending, state => {
      return { ...initialState, loadingStatus: LoadingStatus.InProgress };
    });
    builder.addCase(fetchUserInfo.fulfilled, (state, action) => {
      return { ...action.payload, loadingStatus: LoadingStatus.Loaded };
    });
    builder.addCase(fetchUserInfo.rejected, state => {
      return { ...initialState, loadingStatus: LoadingStatus.Failed };
    });
  }
});

export const userInfoActions = { ...userInfoSlice.actions, fetchUserInfo };
export const userInfoReducer = userInfoSlice.reducer;
