import { Status } from "../constatns/Notification/Status";
import { Priority } from "../constatns/Notification/Priority";
import { FieldNames } from "../utils/fieldNames";

export interface Notification {
  contentGuid: string;
  id: number;
  priority: Priority;
  publishDate: string;
  readDate?: string;
  status: Status;
  title: string;
}

export const notificationFieldNames: FieldNames<Notification> = {
  contentGuid: "contentGuid",
  id: "id",
  priority: "priority",
  publishDate: "publishDate",
  readDate: "readDate",
  status: "status",
  title: "title"
};
