import { createGlobalStyle } from "styled-components/macro";
import { Theme } from "./defaultTheme";

const GlobalStyle = createGlobalStyle`
  #root {
    height: 100vh;
    min-height: 100%;
  }

  body {
    background-color: ${({ theme }: { theme: Theme }) => theme.colors.gray10};
    font-family: ${({ theme }) => theme.fontFamily.primary}, sans-serif;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: touch;

    *, *:before, *:after {
      box-sizing: border-box;
      transition-duration: 0.25s;
      transition-timing-function: ease-in-out;
      transition-property: color, background-color, padding, border-color, border-width, box-shadow, stroke, fill;
    }
  }
`;

export default GlobalStyle;
