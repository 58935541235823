import React from "react";
import styled from "styled-components/macro";
import { t } from "../../intl";

interface ReadOnlyFieldProps {
  labelTrKey: string;
  value: string;
}

export const ReadOnlyField = ({ labelTrKey, value }: ReadOnlyFieldProps) => {
  return (
    <FieldWrapper>
      <Label>{t(labelTrKey)}</Label>
      <Value>{value}</Value>
    </FieldWrapper>
  );
};

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
  gap: 8px;
`;

const Label = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray80};
`;

const Value = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray100};
`;
