import { useCallback } from "react";
import { doGet } from "./ApiService";

const useAvailableAppsService = () => {
  const getApps = useCallback(() => doGet("/home/api/tiles"), [doGet]);

  return { getApps };
};

export default useAvailableAppsService;
