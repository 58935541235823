import React from "react";
import styled from "styled-components";
import { Icon } from "../PruIcon/Icon";

interface GridPaginationProps {
  total: number;
  skip: number;
  take: number;
  onSkipChanged: (skip: number) => void;
}

const range = (start: number, end: number) => [...Array(end - start + 1)].map((_, i) => start + i);

const pagesInCarrousel = 4;
const maxPagesToShow = 6;

const getFirstPage = (
  all: number,
  current: number,
  showPagesLinkBefore: boolean,
  showPagesLinkAfter: boolean
) => {
  if (!showPagesLinkBefore) {
    return 1;
  }

  if (!showPagesLinkAfter) {
    return all - pagesInCarrousel + 1;
  }

  return Math.ceil(current / pagesInCarrousel) * pagesInCarrousel - pagesInCarrousel + 1;
};

const getLastPage = (
  all: number,
  current: number,
  firstPage: number,
  showPagesLinkAfter: boolean
) => (showPagesLinkAfter ? firstPage + pagesInCarrousel - 1 : all);

export const GridPagination = ({ total, skip, take, onSkipChanged }: GridPaginationProps) => {
  const activePrevious = skip !== 0;
  const activeNext = total - skip > take;
  const allPages = Math.ceil(total / take);
  const currentPage = Math.trunc(skip / take + 1);

  const showCarrousel = allPages > maxPagesToShow;

  const showPagesLinkBefore = showCarrousel && currentPage > pagesInCarrousel;
  const showPagesLinkAfter = showCarrousel && currentPage <= allPages - pagesInCarrousel;

  const firstPage = getFirstPage(allPages, currentPage, showPagesLinkBefore, showPagesLinkAfter);
  const lastPage = getLastPage(allPages, currentPage, firstPage, showPagesLinkAfter);

  const pages = range(firstPage, lastPage);

  const setPrevious = () => {
    onSkipChanged((currentPage - 2) * take);
  };
  const setNext = () => {
    onSkipChanged(currentPage * take);
  };

  const handlePageChanged = (page: number) => {
    if (currentPage !== page) {
      onSkipChanged((page - 1) * take);
    }
  };

  const showPagesBefore = () => {
    const newPage = firstPage - 1;
    if (newPage > 0) {
      handlePageChanged(newPage);
    } else {
      handlePageChanged(1);
    }
  };

  const showPagesAfter = () => {
    const newPage = lastPage + 1;
    if (newPage < allPages) {
      handlePageChanged(newPage);
    } else {
      handlePageChanged(allPages);
    }
  };

  return (
    <>
      {total > 0 && (
        <Wrapper>
          {activePrevious && (
            <Icon
              name="chevron-left"
              color="secondary100"
              hoverColor="primary80"
              size={20}
              onClick={setPrevious}
            />
          )}
          {!activePrevious && <Icon name="chevron-left" color="gray60" size={20} />}
          {showPagesLinkBefore && <PagesLink onClick={showPagesBefore} status={"default"} />}
          {pages.map(page => (
            <Page
              key={page}
              onClick={() => handlePageChanged(page)}
              status={page === currentPage ? "active" : "default"}
            >
              {page}
            </Page>
          ))}
          {showPagesLinkAfter && <PagesLink onClick={showPagesAfter} status={"default"} />}
          {activeNext && (
            <Icon
              name="chevron-right"
              color="secondary100"
              hoverColor="primary80"
              size={20}
              onClick={setNext}
            />
          )}
          {!activeNext && <Icon name="chevron-right" color="gray60" size={20} />}
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  height: 32px;
`;

const Page = styled.div<{ status: "default" | "active" }>`
  width: 32px;
  height: 32px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${({ theme, status }) =>
    status === "active" ? theme.colors.primary10 : theme.colors.gray100};
  background: ${({ theme, status }) =>
    status === "active" ? theme.colors.primary100 : theme.colors.white};
  border-radius: 2px;

  &:hover {
    color: ${({ theme }) => theme.colors.gray100};
    background: ${({ theme }) => theme.colors.primary10};
  }
`;

const PagesLink = styled(Page)`
  :before {
    content: "...";
  }
`;
