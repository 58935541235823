import React, { FC } from "react";
import { getWebmailErrorMessage } from "./getWebmailErrorMessage";
import styled from "styled-components/macro";

interface WebServiceErrorProps {
  app: string;
  errorCode: string;
}

export const WebServiceError: FC<WebServiceErrorProps> = ({ app, errorCode }) => {
  const getErrorMessage = () => {
    if (app && errorCode) {
      switch (app) {
        case "webmail":
          return getWebmailErrorMessage(parseInt(errorCode, 10));
      }
    }
  };

  const errorMessage = getErrorMessage();

  return errorMessage ? <Alert>{errorMessage}</Alert> : null;
};

const Alert = styled.div`
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  margin-top: 7rem;
  border-radius: 0.25rem;
`;
