import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  GridState,
  SortDescriptor
} from "../models/GridState";

const buildTake = (take?: number) => (take ? `take=${take}` : "");

const buildSkip = (skip?: number) => (skip ? `skip=${skip}` : "");

const buildSortElement = (fieldName: string, fieldValue: string, index: number): string =>
  `sort[${index}].${fieldName}=${fieldValue}`;

const buildSort = (sort?: Array<SortDescriptor>): string => {
  if (!sort) {
    return "";
  }
  return sort
    .map((part, index) =>
      [
        buildSortElement("field", part.field, index),
        part.dir ? buildSortElement("dir", part.dir, index) : ""
      ]
        .filter(item => item)
        .join("&")
    )
    .join("&");
};

const buildFilter = (prefix: string, filter: FilterDescriptor): string => {
  return [
    `${prefix}.field=${filter.field}`,
    `${prefix}.operator=${filter.operator}`,
    `${prefix}.value=${filter.value}`
  ].join("&");
};

const buildFilters = (prefix: string, filter?: CompositeFilterDescriptor): string => {
  if (!filter) {
    return "";
  }

  return [
    `${prefix}.logic=${filter.logic}`,
    ...filter.filters.map((filter, index) =>
      "filters" in filter
        ? buildFilters(`${prefix}.filters[${index}]`, filter)
        : buildFilter(`${prefix}.filters[${index}]`, filter)
    )
  ]
    .filter(item => item)
    .join("&");
};

export const buildQueryString = (state: GridState): string => {
  return encodeURI(
    [
      buildTake(state.take),
      buildSkip(state.skip),
      buildSort(state.sort),
      buildFilters("filter", state.filter)
    ]
      .filter(part => part)
      .join("&")
  );
};
