import React, { useEffect, useState } from "react";
import { browserName, osName } from "react-device-detect";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import PageLayout from "../../layouts/PageLayout";
import useAuthService from "../../services/useAuthService";
import { MfaFieldNames } from "./MfaFieldNames";
import { useDispatch } from "react-redux";
import { initApp } from "../../thunks/initAppThunk";
import { t } from "../../intl";
import smartphoneIcon from "../../assets/smartphone.svg";
import { Button } from "../../components/Button/Button";
import { redirect, RoutePath } from "../../utils/redirect";
import { LinkButton } from "../../components/LinkButton/LinkButton";
import { Checkbox } from "../../components/Checkbox/Checkbox";
import { Loader } from "../../components/Loader/Loader";
import { InputReactHookFormAdapter } from "../../components/PruInput/InputReactHookFormAdapter";

interface FormValues {
  [MfaFieldNames.authorizationCode]: string;
  [MfaFieldNames.trustedDevice]: boolean;
}

export interface MfaPageProps {
  setSpinnerVisible: (isVisible: boolean) => void;
}

export const MfaPage = (props: MfaPageProps) => {
  const dispatch = useDispatch();
  const { activateSessionInit, activateSessionConfirm } = useAuthService();
  const { checkAuthentication } = useAuthService();
  const [smsSending, setSmsSending] = useState(false);
  const [smsSendingBlocked, setSmsSendingBlocked] = useState(true);
  const { state } = useLocation();

  const formMethods = useForm<FormValues>({
    mode: "onChange",
    reValidateMode: "onChange",
    criteriaMode: "all",
    defaultValues: {
      [MfaFieldNames.authorizationCode]: "",
      [MfaFieldNames.trustedDevice]: true
    }
  });
  const { register, watch, handleSubmit, setValue, formState, setFocus } = formMethods;
  const data = watch();
  useEffect(() => {
    setFocus(MfaFieldNames.authorizationCode);
    setTimeout(() => setSmsSendingBlocked(false), 60000);
  }, [state, setFocus]);

  const onSubmit = (formData: FormValues) => {
    props.setSpinnerVisible(true);
    activateSessionConfirm(
      formData[MfaFieldNames.authorizationCode],
      !formData[MfaFieldNames.trustedDevice] ? "N" : "Y",
      `${osName} ${browserName}`
    )
      .then(() => checkAuthentication())
      .then(() => dispatch(initApp()))
      .finally(() => props.setSpinnerVisible(false));
  };

  const onResendSms = () => {
    if (smsSending || smsSendingBlocked) return;

    setSmsSending(true);
    activateSessionInit()
      .then(response => {
        setTimeout(() => setSmsSending(false), 1000);

        setSmsSendingBlocked(true);
        setTimeout(() => setSmsSendingBlocked(false), response.waitTimeInSeconds * 1000);
      })
      .catch(() => setSmsSending(false));
  };

  const onTrustedDeviceChange = (val: boolean) => {
    setValue(MfaFieldNames.trustedDevice, val);
  };

  const redirectToLogin = () => redirect(RoutePath.login);
  return (
    <PageLayout logoutVisible={false}>
      <MfaWrapper>
        <FormWrapper onSubmit={handleSubmit(onSubmit)}>
          <UnknownDeviceHeaderIcon>
            <img src={smartphoneIcon} alt={"Mfa"} />
          </UnknownDeviceHeaderIcon>
          <UnknownDeviceHeaderText>{t("mfa.header", { br: <br /> })}</UnknownDeviceHeaderText>

          <FormInputs>
            <FormHeader>{t("mfa.subHeader", { br: <br /> })}</FormHeader>
            <InputWrapper>
              <InputReactHookFormAdapter
                labelProps={{
                  labelTrKey: "mfa.input.password.label"
                }}
                registerProps={register(MfaFieldNames.authorizationCode, { required: true })}
                inputProps={{
                  value: data.authorizationCode,
                  placeholderTrKey: "mfa.input.password.placeholder",
                  inputType: "password",
                  onEnter: handleSubmit(onSubmit),
                  autoComplete: "off"
                }}
                setValue={setValue}
              />
              <LinkButton
                textTrKey={"mfa.button.sendSmsAgain"}
                onClick={onResendSms}
                disabled={smsSendingBlocked}
              />
            </InputWrapper>
            <HorizontalLine />
            {smsSending && <Loader />}

            <AddToTrusted>
              <AddToTrustedText>{t("mfa.label.addToTrusted")}</AddToTrustedText>
              <Checkbox
                {...register(MfaFieldNames.trustedDevice, { required: false })}
                checked={data.trustedDevice}
                onValueChange={onTrustedDeviceChange}
              />
            </AddToTrusted>
          </FormInputs>
          <FormButtons>
            <Button
              buttonType={"secondary"}
              onClick={redirectToLogin}
              leftIconName={"arrow-left"}
              textTrKey={"button.cancel"}
            />
            <Button
              buttonType={"primary"}
              type={"submit"}
              disabled={!formState.isValid}
              textTrKey={"button.next"}
            />
          </FormButtons>
        </FormWrapper>
      </MfaWrapper>
    </PageLayout>
  );
};

const MfaWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px;
`;

const FormWrapper = styled.form`
  height: 100%;
  max-width: 440px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
`;

const UnknownDeviceHeaderIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  height: 80px;
  width: 80px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
`;

const UnknownDeviceHeaderText = styled.div`
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: ${({ theme }) => theme.colors.gray100};
  text-align: center;
`;

const FormInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.elevation1};
`;

const FormHeader = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.gray100};
  text-align: center;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
`;

const AddToTrusted = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const AddToTrustedText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray100};
  width: 100%;

  ${({ theme }) => theme.media.aboveMd} {
    width: 80%;
  }
`;

const FormButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

const HorizontalLine = styled.div`
  width: 100%;
  height: 0px;
  border-top: 1px solid ${({ theme }) => theme.colors.gray30};
`;
