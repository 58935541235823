import React, { useCallback, useState } from "react";
import { useModal } from "react-modal-hook";
import ConfirmModal from "../components/ConfirmModal/ConfirmModal";
import { resolveAuthenticationErrorTrKey } from "../utils/resolveAuthenticationErrorTrKey";
import { redirect, RoutePath } from "../utils/redirect";

interface ApiRequest {
  url: string;
  method: string;
  extractItems: boolean;
  body?: any;
  headers?: Headers;
  abortSignal?: AbortSignal;
}

interface ModalProps {
  messageTrKey?: string;
}

const initialModalProps: ModalProps = {
  messageTrKey: ""
};

const useApi = () => {
  const [errorModalState, setErrorModalState] = useState<ModalProps>(initialModalProps);
  const [showErrorModal, hideErrorModal] = useModal(
    () => (
      <ConfirmModal
        messageTrKey={errorModalState.messageTrKey}
        confirmTrKey={"button.ok"}
        onConfirm={hideErrorModal}
        zindex={100}
      />
    ),
    [errorModalState]
  );

  const handleAuthorizationError = useCallback(
    (error: any) => {
      // auth error codes start with "0"
      if (error?.errorCode?.startsWith("0")) {
        setErrorModalState({
          messageTrKey: "api.unexpectedAuthorizationError"
        });

        showErrorModal();
      }
      if (window.location.pathname !== "/home/login") {
        //TODO: refactor after adding Redux
        window.location.pathname = "/home/login";
      }
    },
    [showErrorModal]
  );

  const extractFromItems = (data: any) => (data?.items ? data.items : data);

  const doFetch = useCallback(
    async (apiRequest: ApiRequest) => {
      try {
        const result = await fetch(apiRequest.url, {
          method: apiRequest.method,
          body: apiRequest.body,
          credentials: "same-origin",
          headers: apiRequest.headers || {
            "content-type": "application/json;charset=UTF-8"
          },
          signal: apiRequest?.abortSignal
        });
        if (!result.ok) {
          throw result;
        }

        return result
          .text()
          .then(text =>
            text
              ? apiRequest.extractItems === true
                ? extractFromItems(JSON.parse(text))
                : JSON.parse(text)
              : {}
          );
      } catch (error) {
        const parsedError = await error.json().catch(console.log);

        let errorMessageTrKey;
        // auth error codes start with "0"
        if (parsedError?.errorCode?.startsWith("0")) {
          errorMessageTrKey = resolveAuthenticationErrorTrKey(parsedError?.errorCode);
          console.log("useApi", parsedError?.errorCode, errorMessageTrKey);
        }

        if (error.status === 401) {
          handleAuthorizationError(parsedError);
        } else if (error.status === 409) {
          if (window.location.pathname !== "/home/mfa") {
            redirect(RoutePath.mfa);
          }
          return await Promise.reject(error);
        } else {
          setErrorModalState({
            messageTrKey: errorMessageTrKey || "api.internalServerError"
          });
          showErrorModal();
        }

        return Promise.reject(parsedError);
      }
    },
    [handleAuthorizationError, showErrorModal]
  );

  const doGet = (url: string, abortSignal?: AbortSignal) =>
    doFetch({
      url: url,
      method: "GET",
      extractItems: true,
      abortSignal
    });

  const doGetRaw = (url: string, abortSignal?: AbortSignal) =>
    doFetch({
      url: url,
      method: "GET",
      extractItems: false,
      abortSignal
    });

  const doPost = (url: string, body?: any, headers?: Headers, abortSignal?: AbortSignal) =>
    doFetch({
      url: url,
      method: "POST",
      body: body && JSON.stringify(body),
      headers: headers,
      extractItems: true,
      abortSignal
    });

  const doDelete = (url: string, abortSignal?: AbortSignal) =>
    doFetch({
      url: url,
      method: "DELETE",
      extractItems: true,
      abortSignal
    });

  return { doGet, doGetRaw, doPost, doDelete };
};

export default useApi;
