import React from "react";
import { Priority } from "../../constatns/Notification/Priority";
import { t } from "../../intl";
import { Badge, BadgeProps } from "../Badge/Badge";

interface NotificationPriorityProps {
  priority: Priority;
  maxWidth?: number;
}

const getBadgeProps = (priority: Priority): BadgeProps => {
  switch (priority) {
    case Priority.info: {
      return { backgroundColor: "infoBg", color: "gray100", rectangleColor: "info" };
    }
    case Priority.high: {
      return { backgroundColor: "warningBg", color: "gray100", rectangleColor: "warning" };
    }
    case Priority.urgent: {
      return { backgroundColor: "errorBg", color: "error", rectangleColor: "error" };
    }
  }
};

export const NotificationPriority = ({ priority, maxWidth }: NotificationPriorityProps) => {
  return (
    <Badge
      {...getBadgeProps(priority)}
      text={t(`notification.priority.${priority}`)}
      maxWidth={maxWidth}
    />
  );
};
