import { createAsyncThunk } from "@reduxjs/toolkit";
import { markReadNotification, postponeNotification } from "../services/NotificationService";
import { notificationsActions } from "../slices/notificationsSlice";

export const markAsRead = createAsyncThunk(
  "notification/markAsRead",
  async (notificationId: number, thunkAPI) => {
    await markReadNotification(notificationId).then(item =>
      thunkAPI.dispatch(
        notificationsActions.markRead({ notificationId: notificationId, updatedNotification: item })
      )
    );
  }
);

export const postpone = createAsyncThunk("notification/postpone", async (_, thunkAPI) => {
  await postponeNotification().then(item => thunkAPI.dispatch(notificationsActions.postpone()));
});
