import { SortDescriptor } from "../../models/GridState";

const sameSortDescriptors = (item1: SortDescriptor, item2: SortDescriptor) =>
  item1.field === item2.field && item1.dir === item2.dir;

const sameField = (item1: SortDescriptor, item2: SortDescriptor) => item1.field === item2.field;

export const getSortState = (newSort: SortDescriptor, currentSort?: Array<SortDescriptor>) => {
  if (!currentSort) {
    return [newSort];
  }
  if (currentSort.find(item => sameSortDescriptors(item, newSort))) {
    return [...currentSort.filter(item => !sameSortDescriptors(item, newSort))];
  }
  if (currentSort.find(item => sameField(item, newSort))) {
    return [...currentSort.filter(item => !sameField(item, newSort)), newSort];
  }
  return [...currentSort, newSort];
};
