import React, { ReactNode, useState } from "react";
import styled from "styled-components";
import { Icon } from "../PruIcon/Icon";
import { t } from "../../intl";

interface WarningProps {
  showOnClose?: boolean;
  children?: ReactNode;
  message?: string;
  messageTrKey?: string;
  onClose?: () => void;
}

export const Warning = ({
  showOnClose,
  children,
  message,
  messageTrKey,
  onClose
}: WarningProps) => {
  const [warningVisible, setWarningVisible] = useState(true);
  const closeWarning = () => {
    setWarningVisible(false);
    onClose && onClose();
  };
  return warningVisible ? (
    <Wrapper>
      <Content>
        <Icon name={"alert-triangle"} size={20} color="error" />
        <TextWrapper>
          {messageTrKey && <Text>{t(messageTrKey)}</Text>}
          {message && <Text>{message}</Text>}
          {children && children}
        </TextWrapper>
      </Content>
      {!!showOnClose && (
        <StyledIcon
          name={"x-close"}
          size={20}
          onClick={closeWarning}
          color="gray100"
          hoverColor="grayDark"
        />
      )}
    </Wrapper>
  ) : (
    <></>
  );
};

const Wrapper = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.errorBg};
  border: 1px solid ${({ theme }) => theme.colors.gray40};
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Text = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray100};
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;
