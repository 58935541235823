import React, { createContext, FC, useState } from "react";
import styled, { ThemeProvider } from "styled-components/macro";
import { Normalize } from "styled-normalize";
import AppRoutes from "./AppRoutes";
import { defaultTheme } from "./theme/defaultTheme";
import GlobalStyle from "./theme/GlobalStyle";
import { ModalProvider } from "react-modal-hook";
import { Environment } from "./constatns/Environment";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store";
import { Initializer } from "./components/Initializer";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const AuthContext = createContext<{
  username: string;
  setUsername: (username: string) => void;
}>({
  username: "",
  setUsername: (username: string) => undefined
});

export const EnvironmentContext = createContext<{
  environment?: Environment;
  setEnvironment: (environment: Environment) => void;
}>({
  environment: undefined,
  setEnvironment: (environment: Environment) => undefined
});

const App: FC = () => {
  const [username, setUsername] = useState("");
  const [environment, setEnvironment] = useState<Environment | undefined>(undefined);

  return (
    <Provider store={store}>
      <ThemeProvider theme={defaultTheme}>
        <ModalProvider>
          <Normalize />
          <GlobalStyle />
          <Wrapper>
            <AuthContext.Provider value={{ username, setUsername }}>
              <EnvironmentContext.Provider value={{ environment, setEnvironment }}>
                <BrowserRouter basename={"/home"}>
                  <Initializer />
                  <AppRoutes />
                </BrowserRouter>
              </EnvironmentContext.Provider>
            </AuthContext.Provider>
          </Wrapper>
        </ModalProvider>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
