import React from "react";
import styled, { css } from "styled-components";
import { Icon, IconWeight } from "../PruIcon/Icon";

interface TheadCellProps {
  text: string;
  canSort?: boolean;
  upSelected?: boolean;
  downSelected?: boolean;
  onUp?: () => void;
  onDown?: () => void;
}

export const TheadCell = ({
  text,
  canSort,
  onUp,
  upSelected,
  onDown,
  downSelected
}: TheadCellProps) => {
  const sortHandler = () => {
    if (onDown && onUp) {
      if (upSelected) {
        onDown();
        return;
      }

      onUp();
      return;
    }
  };

  return (
    <Thead>
      <HeaderWrapper>
        <Text boldText={upSelected || downSelected} onClick={() => sortHandler()}>
          {text}
        </Text>
        {canSort && (
          <Sort>
            {onUp && (
              <StyledIconUp
                name={"sort-chev-up"}
                onClick={onUp}
                color={upSelected ? "primary100" : "gray40"}
                size={10}
                weight={IconWeight.bold}
                selected={upSelected}
              />
            )}
            {onDown && (
              <StyledIconDown
                name={"sort-chev-down"}
                onClick={onDown}
                color={downSelected ? "primary100" : "gray40"}
                size={10}
                weight={IconWeight.bold}
                selected={downSelected}
              />
            )}
          </Sort>
        )}
      </HeaderWrapper>
    </Thead>
  );
};

const Thead = styled.th`
  background: ${({ theme }) => theme.colors.white};
  height: 56px;
  padding: 13px 12px;
  @media (max-height: 960px) {
    height: 46px;
    padding: 18px 12px;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  cursor: pointer;
`;

const Text = styled.div<{ boldText?: boolean }>`
  font-weight: ${({ boldText }) => (boldText ? 600 : 400)};
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray80};
`;

const Sort = styled.div`
  height: 18px;
  display: flex;
  flex-direction: column;
  gap: 0px;
`;

const StyledIcon = styled(Icon)<{ selected?: boolean }>`
  & i,
  i:before {
    transition-duration: unset;
    transition-timing-function: unset;
  }

  &:hover:not(:disabled) {
    ${({ selected, theme }) =>
      !selected &&
      css`
        color: ${theme.colors.primary80};
      `}
  }
`;

const StyledIconUp = styled(StyledIcon)`
  margin-bottom: -2px;
`;

const StyledIconDown = styled(StyledIcon)`
  margin-top: -1px;
`;
