export enum LoginErrorCode {
  AUTHENTICATION_UNEXPECTED_ERROR_CODE = "100",
  INVALID_CREDENTIALS_ERROR_CODE = "101",
  ACCOUNT_PASSWORD_MUST_CHANGE_ERROR_CODE = "102",
  INVALID_LOGIN_LOCATION_ERROR_CODE = "103",
  INVALID_LOGIN_TIME_ERROR_CODE = "104",
  ACCOUNT_EXPIRED_ERROR_CODE = "105",
  ACCOUNT_LOCKED_ERROR_CODE = "106",
  CREDENTIAL_EXPIRED_ERROR_CODE = "107",
  ACCOUNT_DISABLED_ERROR_CODE = "108"
}
