import React from "react";
import { FormInputProps, InputFormElement, InputFormElementProps } from "./InputFormElement";
import { UseFormRegisterReturn, UseFormSetValue } from "react-hook-form/dist/types/form";

type ReactHookFormInputProps = Omit<FormInputProps, keyof UseFormRegisterReturn | "onBlurInternal">;
type InputReactHookFormAdapterProps<T> = Omit<InputFormElementProps, "inputProps"> & {
  registerProps: UseFormRegisterReturn;
} & { inputProps: ReactHookFormInputProps } & {
  setValue: UseFormSetValue<T>;
};

export const InputReactHookFormAdapter = <T extends {}>({
  inputProps,
  registerProps,
  setValue,
  ...rest
}: InputReactHookFormAdapterProps<T>) => {
  const handleChange = (value: string) => {
    inputProps.onValueChange && inputProps.onValueChange(value);
    // @ts-ignore
    setValue(registerProps.name, value, {
      shouldValidate: true
    });
  };

  return (
    <InputFormElement
      {...rest}
      inputProps={{
        ...inputProps,
        name: registerProps.name,
        onBlurInternal: registerProps.onBlur,
        onValueChange: handleChange,
        innerRef: registerProps.ref
      }}
    />
  );
};
