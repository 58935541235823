import { LoginErrorCode } from "../constatns/LoginErrorCode";

export const resolveLoginError = (code: string): string | undefined => {
  switch (code) {
    case LoginErrorCode.AUTHENTICATION_UNEXPECTED_ERROR_CODE:
      return "Niespodziewany błąd uwierzytelniania.";
    case LoginErrorCode.INVALID_CREDENTIALS_ERROR_CODE:
      return "Niepoprawne dane logowania.";
    case LoginErrorCode.ACCOUNT_PASSWORD_MUST_CHANGE_ERROR_CODE:
      return "Hasło musi zostać zmienione.";
    case LoginErrorCode.INVALID_LOGIN_LOCATION_ERROR_CODE:
      return "Niepoprawna lokalizacja.";
    case LoginErrorCode.INVALID_LOGIN_TIME_ERROR_CODE:
      return "Logowanie poza godzinami czasu pracy.";
    case LoginErrorCode.ACCOUNT_EXPIRED_ERROR_CODE:
      return "Konto wygasło.";
    case LoginErrorCode.ACCOUNT_LOCKED_ERROR_CODE:
      return "Dostęp został zablokowany. Odblokuj hasło poprzez SMS lub skontaktuj się z infolinią.";
    case LoginErrorCode.CREDENTIAL_EXPIRED_ERROR_CODE:
      return "Hasło wygasło i musi zostać zmienione.";
    case LoginErrorCode.ACCOUNT_DISABLED_ERROR_CODE:
      return "Użytkownik został zablokowany. Skontaktuj się z infolinią pod numerem +48 459 595 100.";
  }

  return undefined;
};
